

import React, { useEffect, useState } from 'react';
import './Loani.css';
import Table from '../components/Table';
import DashboardLayout from '../components/DashboardLayout';
import { getAllConsumers, getAllLoanInterestedConsumer, getAllMediclaimCompany, getAllMediclaimProduct, getAllVerticle, getAllVerticleUser, updateLoanWorkingStatus } from '../serviceAPI/userAPI';
import { useToaster } from '../components/Toaster';
import viewImage from './view.png';
import { useNavigate, useParams } from 'react-router';
import Popup from '../components/MediclaimProduct-popup';

const MediclaimProduct = () => {
    const addToast = useToaster()

    const [data, setData] = useState([]);
    const [verticle, setVerticle] = useState([]);
    const [verticleUser, setVerticleUser] = useState([]);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [heading, setHeading] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const navigate = useNavigate()
    const {id} = useParams()

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
        setEditIndex(null);
    };

    useEffect(() => {
        getAllMediclaimCompanyData();
    }, []);

    const handleViewClick = (item) => {
        // Add your view click handling logic here
        console.log('View clicked for item:', item);
        navigate(`/unit/${item.unit_id}`)
    };

    const getAllMediclaimCompanyData = async () => {
        if(id){
            let obj={mediclaim_company_id:id}
             const consumerData = await getAllMediclaimProduct(obj);
             if (consumerData?.data && consumerData?.data?.length) {
                 const processedData = consumerData?.data.map(item => ({
                     ...item,
                     view: <a><img className="building-view" src={viewImage} alt="View" style={{ cursor: 'pointer' }} onClick={() => handleViewClick(item)} /></a>
                 }));
                 setData(processedData);
             } else {
                 setData([]);
             }
             setHeading([{ key: 'mediclaim_product_name', head: 'Name' } , {key: 'mediclaimproductpdfs', head: 'pdfs'}]);
         }
    };

    const handleEdit = (index) => {
        setEditIndex(index);
        setIsPopupOpen(true);
    };

    const handleSort = (column) => {
        const direction = sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc';
        setSortColumn(column);
        setSortDirection(direction);
    };

    const fetchApi = () => {
        getAllMediclaimCompanyData();
    };


    return (
        <DashboardLayout>
            <div className='d-flex justify-content-between align-items-center dashboard-title'>
                <h1>Mediclaim Product</h1>
                <button className="btn dashboard-btn" onClick={togglePopup}>Add</button>
                {isPopupOpen && <Popup
                    isOpen={isPopupOpen}
                    onClose={togglePopup}
                    fetchApi={fetchApi}
                    initialData={editIndex !== null ? data[editIndex] : null}
                  />}
            </div>
        <div className='loani'>
            
            {(
                <Table
                    headings={heading}
                    data={data}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    itemsPerPage={itemsPerPage}
                    handleEdit={handleEdit}
                    handleSort={handleSort}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                    setItemsPerPage={setItemsPerPage}
                />
            )}
        </div>
        </DashboardLayout>
    );
};

export default MediclaimProduct;
