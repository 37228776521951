import React, { useEffect, useState } from 'react';
import './popup-u.css';
import { addMediclaimUser, DOWNLOAD_URL, getAllMediclaimCompany, getAllMediclaimProduct, updateMediclaimUser } from '../serviceAPI/userAPI';
import { useToaster } from './Toaster';

const MediclaimPopup = ({ isOpen, onClose, fetchApi, initialData, view }) => {
  console.log(view)
  const addToast = useToaster()
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [companyData, setCompanyData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [productType, setProductType] = useState('');
  const [companyType, setCompanyType] = useState('');
  const [formState, setFormState] = useState({
    formData: {
      Name: '',
      MobileNumber: '',
      Email: '',
      RadioButton: '',
      DateOfBirth: '',
      Age: '',
      Gender: '',
      RelationshipWithPolicyHolder: '',
      SumInsured: '',
      AdditionalSumInsured: '',
      NoClaimBonus: '',
      PreExistingIllness: '',
      AddOnCover: '',
      PolicyNumber: '',
      PolicyTenure: '',
      ExpiryDate: '',
      ClaimInExpiringPolicy: '',
      NomineeName: '',
      CustomerID: '',
      PolicyPlanType: '',
      poito: '',
      poifrom: '',
      Zone: '',
      PremiumAmount: '',
      PreviousPolicyNumber: '',
      PolicyIssuedDate: '',
      RenewDate: '',
      AgentName: '',
      AgentCode: '',
      AgentContactNumber: '',
      ProductName: '',
      CompanyName: ''
    },
    familyMembers: [{
      DateOfBirth: '',
      Age: '',
      Gender: '',
      RelationshipWithPolicyHolder: ''
    }],
    errors: {},
  });

  useEffect(() => {
    if (initialData && initialData.id) {
      let array = []
      if (initialData && initialData.familymembers) {
        array = initialData.familymembers.map((item) => ({
          DateOfBirth: item.DateOfBirth ? item.DateOfBirth.slice(0, 10) : "", // Ensure date format and default value
          Age: item.Age, // Default empty string if Age is missing
          Gender: item.Gender || "", // Default empty string if Gender is missing
          RelationshipWithPolicyHolder: item.RelationshipWithPolicyHolder || "", // Default empty string
        }));
      }

      setFormState({
        formData: {
          Name: initialData['user.username'] || '',
          MobileNumber: initialData['user.mobileNumber'] || '',
          Email: initialData['user.email'] || '',
          RadioButton: initialData.medicliam_type || '',
          DateOfBirth: initialData.dob && initialData.dob.slice(0, 10) || '',
          Age: initialData?.age,
          Gender: initialData.gender || '',
          RelationshipWithPolicyHolder: initialData.relationshipwithPolicyHolder || '',
          SumInsured: initialData.sumInsured || '',
          AdditionalSumInsured: initialData.additionalSumInsured || '',
          NoClaimBonus: initialData.noClaimBonus || '',
          PreExistingIllness: initialData.preExistingIllness || '',
          AddOnCover: initialData.addOnCover || '',
          PolicyNumber: initialData.policyNumber || '',
          PolicyTenure: initialData.policyTenure || '',
          ExpiryDate: initialData.expiryDate && initialData.expiryDate.slice(0, 10) || '',
          ClaimInExpiringPolicy: initialData.claimInExpiringPolicy || '',
          NomineeName: initialData.nomineeName || '',
          CustomerID: initialData.customerID || '',
          PolicyPlanType: initialData.policyPlanType || '',
          poito: initialData.poito && initialData.poito.slice(0, 10) || '',
          poifrom: initialData.poifrom && initialData.poifrom.slice(0, 10) || '',
          Zone: initialData.zone || '',
          PremiumAmount: initialData.premiumAmount || '',
          PreviousPolicyNumber: initialData.previousPolicyNumber || '',
          PolicyIssuedDate: initialData.policyIssuedDate && initialData.policyIssuedDate.slice(0, 10) || '',
          RenewDate: initialData.renewDate && initialData.renewDate.slice(0, 10) || '',
          AgentName: initialData.agentName || '',
          AgentCode: initialData.agentCode || '',
          AgentContactNumber: initialData.agentContactNumber || '',
          ProductName: initialData.mediclaim_product_id || '',
          CompanyName: initialData.mediclaim_company_id || ''
        },
        familyMembers: array
      })
      setCompanyType(initialData.mediclaim_company_id || '')
      if (initialData.mediclaim_company_id) {
        getProductData(initialData.mediclaim_company_id)
        setProductType(initialData.mediclaim_product_id)
      }
    }
  }, [initialData]);

  useEffect(() => {
    getCompanyData()
  }, []);

  const validateForm = () => {
    const errors = {};
    const { formData, familyMembers } = formState;

    // Validate based on the current step
    if (currentStep === 1) {
      if (!formData.Name) errors.Name = 'Name is required';
      if (!formData.Email || !/\S+@\S+\.\S+/.test(formData.Email)) errors.Email = 'Valid Email is required';
      if (!formData.MobileNumber || formData.MobileNumber.length < 10) errors.MobileNumber = 'Valid Mobile Number is required';
      if (!companyType) errors.CompanyName = 'Company Name is required';
      if (!formData.RadioButton) errors.RadioButton = 'Please select Family or Individual';
    } else if (currentStep === 2) {
      // Validation for the individual or family policy
      if (!formData.RadioButton) errors.RadioButton = 'Please select Family or Individual';

      // Validation for individual policy
      if (formData.RadioButton === 'Individual') {
        if (!formData.DateOfBirth) errors.DateOfBirth = 'Date of Birth is required';
        if (!formData.Gender) errors.Gender = 'Gender is required';
        if (!formData.RelationshipWithPolicyHolder) errors.RelationshipWithPolicyHolder = 'Relationship with Policy Holder is required';
        if (!formData.PreExistingIllness) errors.PreExistingIllness = 'Pre-existing Illness is required';
        if (!formData.SumInsured || isNaN(formData.SumInsured) || formData.SumInsured <= 0) errors.SumInsured = 'Valid Sum Insured is required';
        if (!formData.NoClaimBonus || isNaN(formData.NoClaimBonus) || formData.NoClaimBonus < 0) errors.NoClaimBonus = 'Valid No Claim Bonus is required';
      }

      // Validation for family policy
      if (formData.RadioButton === 'Family') {
        if (!formData.DateOfBirth) errors.DateOfBirth = 'Date of Birth is required';
        if (!formData.Gender) errors.Gender = 'Gender is required';
        if (!formData.RelationshipWithPolicyHolder) errors.RelationshipWithPolicyHolder = 'Relationship with Policy Holder is required';
        if (!formData.PreExistingIllness) errors.PreExistingIllness = 'Pre-existing Illness is required';
        if (!formData.SumInsured || isNaN(formData.SumInsured) || formData.SumInsured <= 0) errors.SumInsured = 'Valid Sum Insured is required';
        if (!formData.NoClaimBonus || isNaN(formData.NoClaimBonus) || formData.NoClaimBonus < 0) errors.NoClaimBonus = 'Valid No Claim Bonus is required';

        // Validate family members
        familyMembers.forEach((member, index) => {
          if (!member.DateOfBirth) errors[`member${index}_DateOfBirth`] = `Date of Birth is required for family member ${index + 1}`;
          if (!member.Gender) errors[`member${index}_Gender`] = `Gender is required for family member ${index + 1}`;
          if (!member.RelationshipWithPolicyHolder) errors[`member${index}_RelationshipWithPolicyHolder`] = `Relationship with Policy Holder is required for family member ${index + 1}`;
        });
      }
    } else if (currentStep === 3) {
      if (!formData.poifrom) errors.poifrom = 'Period of Insurance "From" date is required';
      if (!formData.poito) errors.poito = 'Period of Insurance "To" date is required';

      // Check if "To" date is after "From" date
      if (formData.poifrom && formData.poito && new Date(formData.poito) <= new Date(formData.poifrom)) {
        errors.poito = 'The "To" date must be later than the "From" date';
      }

      if (!formData.AdditionalSumInsured || isNaN(formData.AdditionalSumInsured) || formData.AdditionalSumInsured <= 0) errors.AdditionalSumInsured = 'Additional Sum Insured is required';
      if (!formData.PolicyIssuedDate) errors.PolicyIssuedDate = 'Policy Issued Date is required';

      // Check if "Policy Issued Date" is after "From" date
      if (formData.PolicyIssuedDate && formData.poifrom && new Date(formData.PolicyIssuedDate) > new Date(formData.poifrom)) {
        errors.PolicyIssuedDate = 'Policy Issued Date cannot be after the "Period of Insurance From" date';
      }

      if (!formData.PolicyPlanType) errors.PolicyPlanType = 'Policy Plan Type is required';
    } else if (currentStep === 4) {
      if (!formData.PolicyNumber) errors.PolicyNumber = 'Policy Number is required';
      if (!formData.Zone) errors.Zone = 'Zone is required';
      if (!formData.PolicyTenure || isNaN(formData.PolicyTenure) || formData.PolicyTenure <= 0) errors.PolicyTenure = 'Policy Tenure is required';
      if (!formData.PremiumAmount || isNaN(formData.PremiumAmount) || formData.PremiumAmount <= 0) errors.PremiumAmount = 'Premium Amount is required';
      if (!formData.ClaimInExpiringPolicy) errors.ClaimInExpiringPolicy = 'Claim in Expiring Policy is required';
      if (!formData.PreviousPolicyNumber) errors.PreviousPolicyNumber = 'Previous Policy Number is required';
      if (!formData.NomineeName) errors.NomineeName = 'Nominee Name is required';
      if (!productType) errors.ProductName = 'Product Name is required';
      if (!formData.CustomerID) errors.CustomerID = 'Customer ID is required';
      if (!formData.RenewDate) errors.RenewDate = 'Renew Date is required';
    } else if (currentStep === 5) {
      if (!formData.AgentName) errors.AgentName = 'Agent Name is required';
      if (!formData.AgentContactNumber || formData.AgentContactNumber.length < 10) errors.AgentContactNumber = 'Agent Contact Number is required';
      if (!formData.AgentCode) errors.AgentCode = 'Agent Code is required';
    }

    // Update errors in the state
    setFormState((prevState) => ({
      ...prevState,
      errors,
    }));

    // If no errors, return true
    return Object.keys(errors).length === 0;
  };



  const getProductData = async (id) => {
    let obj = {
      mediclaim_company_id: id
    }
    const roleData = await getAllMediclaimProduct(obj);
    if (roleData?.data && roleData?.data?.length) {
      setProductData(roleData?.data)
    } else {
      setProductData([])
    }
  }

  const redirectpageproduct = (pdfPath) => {
    // const file_path = `${DOWNLOAD_URL}public${pdfPath}`;
    const file_path = DOWNLOAD_URL + pdfPath;
    console.log(file_path, "file");

    // Create a temporary <a> element
    const a = document.createElement("a");
    a.href = file_path;
    a.target = "_blank"; // Open in a new tab
    a.download = file_path; // Extract filename from path

    // Trigger the click programmatically
    document.body.appendChild(a);
    a.click();

    // Remove the element after triggering
    document.body.removeChild(a);
  };
  const getCompanyData = async () => {
    const roleData = await getAllMediclaimCompany();
    if (roleData?.data && roleData?.data?.length)
      setCompanyData(roleData?.data)
  }

  const handleNext = () => {
    if(view){
      if (currentStep < 5) {
        setCurrentStep(currentStep + 1);
      }
    }else{
      if (validateForm()) {
        if (currentStep < 5) {
          setCurrentStep(currentStep + 1);
        }
      }
    }
    
  };

  const handlePrev = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitting) return; // Prevent form from submitting if already submitting

    // Only submit when we're on Step 5
    if (currentStep === 5) {
      if (validateForm()) {
        setIsSubmitting(true); // Set flag to true to prevent multiple submissions

        console.log('Form Submitted', formState.formData);

        // Validate mobile number format
        const regex = /^\d{10}$/;
        if (!regex.test(formState?.formData?.MobileNumber)) {
          alert('Mobile number is invalid');
          setIsSubmitting(false); // Reset the flag
          return;
        }

        // Prepare payload with form data and family members
        const payload = {
          ...formState.formData,
          familyMembers: formState.familyMembers,
          ProductName: productType,
          CompanyName: companyType,
        };

        console.log('Payload:', payload);

        try {
          let response;
          if (initialData && initialData.id) {
            // Update existing record
            payload.user_id = initialData.user_id;
            payload.id = initialData.id;
            response = await updateMediclaimUser(payload, addToast);
          } else {
            // Add new record
            response = await addMediclaimUser(payload, addToast);
          }

          if (response.status) {
            // Success logic
            fetchApi();
            onClose();
          }
        } catch (error) {
          console.error('Error during submission:', error);
        } finally {
          setIsSubmitting(false); // Reset the flag after submission attempt
        }
      }
    }
  };

  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const handleDateChange = (e) => {
    const dateOfBirth = e.target.value;
    const age = calculateAge(dateOfBirth);
    setFormState(prevState => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        DateOfBirth: dateOfBirth,
        Age: age
      }
    }));
  };

  const handleFamilyMemberChange = (index, field, value) => {
    const updatedFamilyMembers = [...formState.familyMembers];

    // Ensure the family member at the given index exists or initialize it
    if (!updatedFamilyMembers[index]) {
      updatedFamilyMembers[index] = {
        DateOfBirth: '',
        Age: '', // Ensure Age is part of the new family member
        Gender: '',
        RelationshipWithPolicyHolder: ''
      };
    }

    // Handle 'DateOfBirth' specifically because we need to calculate 'Age'
    if (field === 'DateOfBirth') {
      const age = calculateAge(value); // Calculate the age based on DateOfBirth

      // If age is valid, update the 'Age' field
      if (age !== null) {
        updatedFamilyMembers[index][field] = value; // Update the DateOfBirth
        updatedFamilyMembers[index]['Age'] = age; // Update the Age
      }
    } else {
      // For all other fields, just update the value directly
      updatedFamilyMembers[index][field] = value;
    }

    // Update the formState with the modified family members
    setFormState(prevState => ({
      ...prevState,
      familyMembers: updatedFamilyMembers
    }));
  };
  console.log(formState.errors)


  const addFamilyMember = () => {
    setFormState(prevState => ({
      ...prevState,
      familyMembers: [
        ...prevState.familyMembers,
        { DateOfBirth: '', Age: '', Gender: '', RelationshipWithPolicyHolder: '' }
      ]
    }));
  };

  const removeFamilyMember = (index) => {
    const updatedFamilyMembers = [...formState.familyMembers];
    updatedFamilyMembers.splice(index, 1);
    setFormState(prevState => ({ ...prevState, familyMembers: updatedFamilyMembers }));
  };

  const onChangeHandle = (e) => {
    setProductType('');
    setFormState(prevState => ({ ...prevState, CompanyName: e.target.value }));
    getProductData(e.target.value)

  }

  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <div className='popup-header d-flex justify-content-between align-items-center'>
          <h2>{initialData ? 'Edit Mediclaim' : 'Add Mediclaim'}</h2>
          <span className="close-btn" onClick={onClose}>&times;</span>
        </div>
        <form className="popup-form" onSubmit={handleSubmit}>
          {currentStep === 1 && (
            <>
              <div className='mediclaim-h1'>
                <h3>Consumer Details</h3>
              </div>
              <div className='row'>
                <div className='col-md-6 mb-4'>
                  <input
                    type="text"
                    name="Name"
                    required
                    className='form-control'
                    placeholder="Name"
                    value={formState.formData.Name}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        Name: e.target.value
                      }
                    }))}
                  />
                  {formState?.errors?.Name && <p className="text-danger">{formState.errors.Name}</p>}
                </div>
                <div className='col-md-6 mb-4'>
                  <input
                    type="email"
                    name="Email"
                    required
                    className='form-control'
                    placeholder="Email"
                    value={formState.formData.Email}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        Email: e.target.value
                      }
                    }))}
                  />
                  {formState?.errors?.Email && <p className="text-danger">{formState.errors.Email}</p>}
                </div>
                <div className='col-md-6 mb-4'>
                  <input
                    type="text"
                    name="MobileNumber"
                    required
                    className='form-control'
                    placeholder="Mobile Number"
                    value={formState.formData.MobileNumber}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        MobileNumber: e.target.value
                      }
                    }))}
                  />
                  {formState?.errors?.MobileNumber && <p className="text-danger">{formState.errors.MobileNumber}</p>}
                </div>
                <div className='col-md-6 mb-4'>
                  <select
                    name="CompanyName"
                    required
                    value={companyType} className='form-select' onChange={(e) => { setCompanyType(e.target.value); onChangeHandle(e) }}>
                    <option value="" disabled hidden>Select Company</option>
                    {companyData && companyData.map((item) => (
                      <option key={item.mediclaim_company_id} value={item.mediclaim_company_id}>
                        {item.mediclaim_company_name}
                      </option>
                    ))}
                  </select>
                  {formState?.errors?.CompanyName && <p className="text-danger">{formState.errors.CompanyName}</p>}
                </div>
                <div className='col-md-6 mb-4 d-grid'>
                  <label className='flex align-items-center'>
                    <input
                      type="radio"
                      name="RadioButton"
                      required
                      className='me-2'
                      value="Family"
                      checked={formState.formData.RadioButton === 'Family'}
                      onChange={(e) => setFormState(prevState => ({
                        ...prevState,
                        formData: {
                          ...prevState.formData,
                          RadioButton: e.target.value
                        }
                      }))}
                    />
                    Family
                  </label>
                  <label className='flex align-items-center'>
                    <input
                      type="radio"
                      name="RadioButton"
                      className='me-2'
                      value="Individual"
                      checked={formState.formData.RadioButton === 'Individual'}
                      onChange={(e) => setFormState(prevState => ({
                        ...prevState,
                        formData: {
                          ...prevState.formData,
                          RadioButton: e.target.value
                        }
                      }))}
                    />
                    Individual
                  </label>
                  {formState?.errors?.RadioButton && <p className="text-danger">{formState.errors.RadioButton}</p>}
                </div>
              </div>
            </>
          )}
          {currentStep === 2 && (
            <>
              <div className='mediclaim-h1'>
                <h3>Running Policy Details</h3>
              </div>
              {formState.formData.RadioButton === 'Family' && (
                <>
                  <div>
                    <button type="button" className='btn btn-blue mb-2 ' onClick={addFamilyMember}>Add Family Member</button>
                  </div>
                  {(formState.formData.RadioButton === 'Family') && (
                    <>
                      <div className='row mt-2'>
                        <div className='col-md-6 mb-4'>
                          <label>Date of birth</label>
                          <input
                            type="date"
                            name="DateOfBirth"
                            className='form-control'
                            placeholder="Date of Birth"
                            value={formState?.formData?.DateOfBirth}
                            onChange={handleDateChange}
                          />
                          {formState?.errors?.DateOfBirth && (
                            <div className="text-danger">{formState?.errors?.DateOfBirth}</div>
                          )}
                        </div>
                        <div className='col-md-6 mb-4'>
                          <label>Age</label>
                          <input
                            type="text"
                            name="Age"
                            className='form-control'
                            placeholder="Age"
                            value={formState?.formData?.Age}
                            readOnly
                          />
                          {formState?.errors?.Age && <div className="text-danger">{formState?.errors?.Age}</div>}
                        </div>
                        <div className='col-md-12 mb-4'>
                          <label>Gender</label>
                          <div className='d-flex'>
                            <label className='me-2'>
                              <input
                                type="radio"
                                name="Gender"
                                className='me-2'
                                value="Male"
                                checked={formState.formData.Gender === 'Male'}
                                onChange={(e) => setFormState(prevState => ({
                                  ...prevState,
                                  formData: {
                                    ...prevState.formData,
                                    Gender: e.target.value
                                  }
                                }))}
                              />
                              Male
                            </label>
                            <label className='me-2'>
                              <input
                                type="radio"
                                name="Gender"
                                className='me-2'
                                value="Female"
                                checked={formState.formData.Gender === 'Female'}
                                onChange={(e) => setFormState(prevState => ({
                                  ...prevState,
                                  formData: {
                                    ...prevState.formData,
                                    Gender: e.target.value
                                  }
                                }))}
                              />
                              Female
                            </label>
                            <label className='me-2'>
                              <input
                                type="radio"
                                name="Gender"
                                className='me-2'
                                value="Other"
                                checked={formState.formData.Gender === 'Other'}
                                onChange={(e) => setFormState(prevState => ({
                                  ...prevState,
                                  formData: {
                                    ...prevState.formData,
                                    Gender: e.target.value
                                  }
                                }))}
                              />
                              Other
                            </label>
                          </div>
                          {formState?.errors?.Gender && (
                            <div className="text-danger">{formState.errors.Gender}</div>
                          )}
                        </div>
                        <div className='col-md-6 mb-4'>
                          <input
                            type="text"
                            className='form-control'
                            name="RelationshipWithPolicyHolder"
                            placeholder="Relationship with Policy Holder"
                            value={formState.formData.RelationshipWithPolicyHolder}
                            onChange={(e) => setFormState(prevState => ({
                              ...prevState,
                              formData: {
                                ...prevState.formData,
                                RelationshipWithPolicyHolder: e.target.value
                              }
                            }))}
                          />
                          {formState?.errors?.RelationshipWithPolicyHolder && (
                            <div className="text-danger">{formState.errors.RelationshipWithPolicyHolder}</div>
                          )}
                        </div>
                        <div className='col-md-6 mb-4'>
                          <input
                            type="text"
                            name="PreExistingIllness"
                            className='form-control'
                            placeholder="Pre Existing Illness"
                            value={formState.formData.PreExistingIllness}
                            onChange={(e) => setFormState(prevState => ({
                              ...prevState,
                              formData: {
                                ...prevState.formData,
                                PreExistingIllness: e.target.value
                              }
                            }))}
                          />
                          {formState?.errors?.PreExistingIllness && (
                            <div className="text-danger">{formState.errors.PreExistingIllness}</div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {formState.formData.RadioButton === 'Family' && (
                    <>
                      {formState.familyMembers && formState.familyMembers.map((member, index) => (
                        <div key={index} className="family-member-section mt-3">
                          <h5>Family Member {index + 1}</h5>
                          <div className='row'>
                            <div className='col-md-6 mb-3'>
                              <label>Date of Birth</label>
                              <input
                                type="date"
                                className='form-control'
                                value={member.DateOfBirth}
                                onChange={(e) => handleFamilyMemberChange(index, 'DateOfBirth', e.target.value)}
                              />
                              {formState?.errors && formState?.errors[`member${index}_DateOfBirth`] && (
                                <div className="text-danger">{formState?.errors[`member${index}_DateOfBirth`]}</div>
                              )}
                            </div>
                            <div className="col-md-6 mb-3">
                              <label>Age</label>
                              <input
                                type="text"
                                value={member.Age}
                                disabled
                                className="form-control"
                              />
                              {formState?.errors && formState?.errors[`member${index}_Age`] && (
                                <div className="text-danger">{formState.errors[`member${index}_Age`]}</div>
                              )}
                            </div>
                            <div className='col-md-6 mb-3'>
                              <label>Gender</label>
                              <div className='d-flex'>
                                <label className='me-2'>
                                  <input
                                    type="radio"
                                    name={`Gender-${index}`}
                                    className='me-2'
                                    value="Male"
                                    checked={member.Gender === 'Male'}
                                    onChange={(e) => handleFamilyMemberChange(index, 'Gender', e.target.value)}
                                  />
                                  Male
                                </label>
                                <label className='me-2'>
                                  <input
                                    type="radio"
                                    name={`Gender-${index}`}
                                    className='me-2'
                                    value="Female"
                                    checked={member.Gender === 'Female'}
                                    onChange={(e) => handleFamilyMemberChange(index, 'Gender', e.target.value)}
                                  />
                                  Female
                                </label>
                                <label className='me-2'>
                                  <input
                                    type="radio"
                                    name={`Gender-${index}`}
                                    className='me-2'
                                    value="Other"
                                    checked={member.Gender === 'Other'}
                                    onChange={(e) => handleFamilyMemberChange(index, 'Gender', e.target.value)}
                                  />
                                  Other
                                </label>
                              </div>
                              {formState?.errors && formState?.errors[`member${index}_Gender`] && (
                                <div className="text-danger">{formState.errors[`member${index}_Gender`]}</div>
                              )}
                            </div>
                            <div className='col-md-6 mb-3'>
                              <label>Relationship with Policy Holder</label>
                              <input
                                type="text"
                                className='form-control'
                                value={member.RelationshipWithPolicyHolder}
                                onChange={(e) => handleFamilyMemberChange(index, 'RelationshipWithPolicyHolder', e.target.value)}
                              />
                              {formState?.errors && formState?.errors[`member${index}_RelationshipWithPolicyHolder`] && (
                                <div className="text-danger">{formState.errors[`member${index}_RelationshipWithPolicyHolder`]}</div>
                              )}
                            </div>
                            <div className='col-md-12'>
                              <button
                                type="button"
                                className='btn btn-danger'
                                onClick={() => removeFamilyMember(index)}
                              >
                                Remove Family Member
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}

                  <div className='row mt-2'>
                    <div className='col-md-6 mb-4'>
                      <input
                        type="number"
                        name="SumInsured"
                        className='form-control'
                        placeholder="Sum Insured"
                        value={formState.formData.SumInsured}
                        onChange={(e) => setFormState(prevState => ({
                          ...prevState,
                          formData: {
                            ...prevState.formData,
                            SumInsured: e.target.value
                          }
                        }))}
                      />
                      {formState?.errors?.SumInsured && (
                        <div className="text-danger">{formState.errors.SumInsured}</div>
                      )}
                    </div>
                    <div className='col-md-6 mb-4'>
                      <input
                        type="number"
                        name="NoClaimBonus"
                        className='form-control'
                        placeholder="No Claim Bonus"
                        value={formState.formData.NoClaimBonus}
                        onChange={(e) => setFormState(prevState => ({
                          ...prevState,
                          formData: {
                            ...prevState.formData,
                            NoClaimBonus: e.target.value
                          }
                        }))}
                      />
                      {formState?.errors?.NoClaimBonus && (
                        <div className="text-danger">{formState.errors.NoClaimBonus}</div>
                      )}
                    </div>
                  </div>
                </>


              )}

              {(formState.formData.RadioButton === 'Individual') && (
                <>
                  <div className='row mt-2'>
                    <div className='col-md-6 mb-4'>
                      <label>Date of birth</label>
                      <input
                        type="date"
                        name="DateOfBirth"
                        className='form-control'
                        placeholder="Date of Birth"
                        value={formState.formData.DateOfBirth}
                        onChange={handleDateChange}
                      />
                      {formState.errors.DateOfBirth && (
                        <div className="text-danger">{formState.errors.DateOfBirth}</div>
                      )}
                    </div>
                    <div className='col-md-6 mb-4'>
                      <label>Age</label>
                      <input
                        type="text"
                        name="Age"
                        className='form-control'
                        placeholder="Age"
                        value={formState.formData.Age}
                        readOnly
                      />
                      {formState.errors.Age && <div className="text-danger">{formState.errors.Age}</div>}
                    </div>
                    <div className='col-md-12 mb-4'>
                      <label>Gender</label>
                      <div className='d-flex'>
                        <label className='me-2'>
                          <input
                            type="radio"
                            name="Gender"
                            className='me-2'
                            value="Male"
                            checked={formState.formData.Gender === 'Male'}
                            onChange={(e) => setFormState(prevState => ({
                              ...prevState,
                              formData: {
                                ...prevState.formData,
                                Gender: e.target.value
                              }
                            }))}
                          />
                          Male
                        </label>
                        <label className='me-2'>
                          <input
                            type="radio"
                            name="Gender"
                            className='me-2'
                            value="Female"
                            checked={formState.formData.Gender === 'Female'}
                            onChange={(e) => setFormState(prevState => ({
                              ...prevState,
                              formData: {
                                ...prevState.formData,
                                Gender: e.target.value
                              }
                            }))}
                          />
                          Female
                        </label>
                        <label className='me-2'>
                          <input
                            type="radio"
                            name="Gender"
                            className='me-2'
                            value="Other"
                            checked={formState.formData.Gender === 'Other'}
                            onChange={(e) => setFormState(prevState => ({
                              ...prevState,
                              formData: {
                                ...prevState.formData,
                                Gender: e.target.value
                              }
                            }))}
                          />
                          Other
                        </label>
                      </div>
                      {formState?.errors?.Gender && (
                        <div className="text-danger">{formState.errors.Gender}</div>
                      )}
                    </div>
                    <div className='col-md-6 mb-4'>
                      <input
                        type="text"
                        className='form-control'
                        name="RelationshipWithPolicyHolder"
                        placeholder="Relationship with Policy Holder"
                        value={formState.formData.RelationshipWithPolicyHolder}
                        onChange={(e) => setFormState(prevState => ({
                          ...prevState,
                          formData: {
                            ...prevState.formData,
                            RelationshipWithPolicyHolder: e.target.value
                          }
                        }))}
                      />
                      {formState?.errors?.RelationshipWithPolicyHolder && (
                        <div className="text-danger">{formState.errors.RelationshipWithPolicyHolder}</div>
                      )}
                    </div>
                    <div className='col-md-6 mb-4'>
                      <input
                        type="text"
                        name="PreExistingIllness"
                        className='form-control'
                        placeholder="Pre Existing Illness"
                        value={formState.formData.PreExistingIllness}
                        onChange={(e) => setFormState(prevState => ({
                          ...prevState,
                          formData: {
                            ...prevState.formData,
                            PreExistingIllness: e.target.value
                          }
                        }))}
                      />
                      {formState?.errors?.PreExistingIllness && (
                        <div className="text-danger">{formState.errors.PreExistingIllness}</div>
                      )}
                    </div>
                    <div className='col-md-6 mb-4'>
                      <input
                        type="number"
                        name="SumInsured"
                        className='form-control'
                        placeholder="Sum Insured"
                        value={formState.formData.SumInsured}
                        onChange={(e) => setFormState(prevState => ({
                          ...prevState,
                          formData: {
                            ...prevState.formData,
                            SumInsured: e.target.value
                          }
                        }))}
                      />
                      {formState?.errors?.SumInsured && (
                        <div className="text-danger">{formState.errors.SumInsured}</div>
                      )}
                    </div>
                    <div className='col-md-6 mb-4'>
                      <input
                        type="number"
                        name="NoClaimBonus"
                        className='form-control'
                        placeholder="No Claim Bonus"
                        value={formState.formData.NoClaimBonus}
                        onChange={(e) => setFormState(prevState => ({
                          ...prevState,
                          formData: {
                            ...prevState.formData,
                            NoClaimBonus: e.target.value
                          }
                        }))}
                      />
                      {formState?.errors?.NoClaimBonus && (
                        <div className="text-danger">{formState.errors.NoClaimBonus}</div>
                      )}

                    </div>

                  </div>
                </>
              )}

            </>
          )}
          {currentStep === 3 && (
            <>
              <div className="mediclaim-h1">
                <h3>Running Policy Details</h3>
              </div>
              <div className="row">
                <div className="col-md-12 mb-4">
                  <label>Period of Insurance</label>
                </div>
                <div className="col-md-6 mb-4">
                  <label>From</label>
                  <input
                    type="date"
                    name="poifrom"
                    className="form-control"
                    value={formState.formData.poifrom}
                    onChange={(e) => {
                      const poifrom = e.target.value;
                      setFormState(prevState => ({
                        ...prevState,
                        formData: {
                          ...prevState.formData,
                          poifrom: poifrom
                        }
                      }));

                      // Validate 'From' date: Ensure it's not later than the 'To' date
                      if (new Date(poifrom) > new Date(formState.formData.poito)) {
                        setFormState(prevState => ({
                          ...prevState,
                          errors: {
                            ...prevState.errors,
                            poifrom: "The 'From' date cannot be later than the 'To' date."
                          }
                        }));
                      } else {
                        setFormState(prevState => ({
                          ...prevState,
                          errors: {
                            ...prevState.errors,
                            poifrom: "" // Clear error
                          }
                        }));
                      }
                    }}
                  />
                  {formState?.errors?.poifrom && <div className="text-danger">{formState.errors.poifrom}</div>}
                </div>
                <div className="col-md-6 mb-4">
                  <label>To</label>
                  <input
                    type="date"
                    name="poito"
                    className="form-control"
                    value={formState.formData.poito}
                    onChange={(e) => {
                      const poito = e.target.value;
                      setFormState(prevState => ({
                        ...prevState,
                        formData: {
                          ...prevState.formData,
                          poito: poito,
                          ExpiryDate: poito // Automatically set Expiry Date based on "To" date
                        }
                      }));

                      // Validate 'To' date: Ensure it's not earlier than 'From' date
                      if (new Date(poito) < new Date(formState.formData.poifrom)) {
                        setFormState(prevState => ({
                          ...prevState,
                          errors: {
                            ...prevState.errors,
                            poito: "The 'To' date cannot be earlier than the 'From' date."
                          }
                        }));
                      } else {
                        setFormState(prevState => ({
                          ...prevState,
                          errors: {
                            ...prevState.errors,
                            poito: "" // Clear error
                          }
                        }));
                      }
                    }}
                  />
                  {formState?.errors?.poito && <div className="text-danger">{formState.errors.poito}</div>}
                </div>
                <div className="col-md-6 mb-4">
                  <input
                    type="number"
                    name="AdditionalSumInsured"
                    className="form-control"
                    placeholder="Additional Sum Insured"
                    value={formState.formData.AdditionalSumInsured}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (isNaN(value) || value < 0) {
                        setFormState(prevState => ({
                          ...prevState,
                          errors: {
                            ...prevState.errors,
                            AdditionalSumInsured: "Please enter a valid positive number for Additional Sum Insured"
                          }
                        }));
                      } else {
                        setFormState(prevState => ({
                          ...prevState,
                          formData: {
                            ...prevState.formData,
                            AdditionalSumInsured: value
                          },
                          errors: {
                            ...prevState.errors,
                            AdditionalSumInsured: "" // Clear error
                          }
                        }));
                      }
                    }}
                  />
                  {formState?.errors?.AdditionalSumInsured && <div className="text-danger">{formState.errors.AdditionalSumInsured}</div>}
                </div>
                <div className="col-md-6 mb-4">
                  <input
                    type="text"
                    name="AddOnCover"
                    className="form-control"
                    placeholder="Add On Cover"
                    value={formState.formData.AddOnCover}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        AddOnCover: e.target.value
                      }
                    }))}
                  />
                </div>
                <div className="col-md-6 mb-4">
                  <label>Policy Issued Date</label>
                  <input
                    type="date"
                    name="PolicyIssuedDate"
                    className="form-control"
                    value={formState.formData.PolicyIssuedDate}
                    onChange={(e) => {
                      const policyIssuedDate = e.target.value;
                      setFormState(prevState => ({
                        ...prevState,
                        formData: {
                          ...prevState.formData,
                          PolicyIssuedDate: policyIssuedDate
                        }
                      }));

                      // Validate 'Policy Issued Date': Ensure it's not later than 'From' date
                      if (new Date(policyIssuedDate) > new Date(formState.formData.poifrom)) {
                        setFormState(prevState => ({
                          ...prevState,
                          errors: {
                            ...prevState.errors,
                            PolicyIssuedDate: "Policy Issued Date cannot be later than the 'From' date."
                          }
                        }));
                      } else {
                        setFormState(prevState => ({
                          ...prevState,
                          errors: {
                            ...prevState.errors,
                            PolicyIssuedDate: "" // Clear error
                          }
                        }));
                      }
                    }}
                  />
                  {formState?.errors?.PolicyIssuedDate && <div className="text-danger">{formState.errors.PolicyIssuedDate}</div>}
                </div>
                <div className="col-md-6 mb-4">
                  <label>Policy Expiry Date</label>
                  <input
                    type="date"
                    name="ExpiryDate"
                    className="form-control"
                    value={formState.formData.ExpiryDate}
                    disabled
                  />
                </div>
                <div className="col-md-6 mb-4">
                  <input
                    type="text"
                    name="PolicyPlanType"
                    className="form-control"
                    placeholder="Policy Plan Type"
                    value={formState.formData.PolicyPlanType}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        PolicyPlanType: e.target.value
                      }
                    }))}
                  />
                </div>
              </div>
            </>
          )}


          {currentStep === 4 && (
            <>
              <div className='mediclaim-h1'>
                <h3>Previous Policy Details</h3>
              </div>
              <div className='row'>
                <div className='col-md-6 mb-4'>
                  <input
                    type="text"
                    name="PolicyNumber"
                    className='form-control'
                    placeholder="Policy Number"
                    value={formState.formData.PolicyNumber}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        PolicyNumber: e.target.value
                      }
                    }))}
                  />
                  {formState?.errors?.PolicyNumber && <div className="text-danger">{formState.errors.PolicyNumber}</div>}
                </div>
                <div className='col-md-6 mb-4'>
                  <input
                    type="text"
                    name="Zone"
                    className='form-control'
                    placeholder="Zone"
                    value={formState.formData.Zone}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        Zone: e.target.value
                      }
                    }))}
                  />
                  {formState?.errors?.Zone && <div className="text-danger">{formState.errors.Zone}</div>}
                </div>
                <div className='col-md-6 mb-4'>
                  <input
                    type="number"
                    name="PolicyTenure"
                    className='form-control'
                    placeholder="Policy Tenure"
                    value={formState.formData.PolicyTenure}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        PolicyTenure: e.target.value
                      }
                    }))}
                  />
                  // {formState.errors?.PolicyTenure && <div className="text-danger">{formState.errors.PolicyTenure}</div>}
                </div>
                <div className='col-md-6 mb-4'>
                  <input
                    type="number"
                    name="PremiumAmount"
                    className='form-control'
                    placeholder="Premium Amount"
                    value={formState.formData.PremiumAmount}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        PremiumAmount: e.target.value
                      }
                    }))}
                  />
                  {formState?.errors?.PremiumAmount && <div className="text-danger">{formState.errors.PremiumAmount}</div>}
                </div>
                <div className='col-md-6 mb-4'>
                  <input
                    type="text"
                    name="ClaimInExpiringPolicy"
                    className='form-control'
                    placeholder="Claim in Expiring Policy"
                    value={formState.formData.ClaimInExpiringPolicy}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        ClaimInExpiringPolicy: e.target.value
                      }
                    }))}
                  />
                  {formState?.errors?.ClaimInExpiringPolicy && <div className="text-danger">{formState.errors.ClaimInExpiringPolicy}</div>}
                </div>
                <div className='col-md-6 mb-4'>
                  <input
                    type="text"
                    name="PreviousPolicyNumber"
                    className='form-control'
                    placeholder="Previous Policy Number"
                    value={formState.formData.PreviousPolicyNumber}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        PreviousPolicyNumber: e.target.value
                      }
                    }))}
                  />
                  {formState?.errors?.PreviousPolicyNumber && <div className="text-danger">{formState.errors.PreviousPolicyNumber}</div>}
                </div>
                <div className='col-md-6 mb-4'>
                  <input
                    type="text"
                    name="NomineeName"
                    className='form-control'
                    placeholder="Nominee Name"
                    value={formState.formData.NomineeName}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        NomineeName: e.target.value
                      }
                    }))}
                  />
                  {formState?.errors?.NomineeName && <div className="text-danger">{formState.errors.NomineeName}</div>}
                </div>
                <div className='col-md-6 mb-4'>
                  <select
                    name="ProductName"
                    className='form-select'
                    // value={formState.formData.ProductName}
                    value={productType}
                    onChange={(e) => {
                      const selectedValue = e.target.value;

                      // Update productType and formState in a single setState function
                      setProductType(selectedValue);
                      console.log(selectedValue, productData, 'dshkdskjhdkskdjkl')
                      setFormState((prevState) => ({
                        ...prevState,
                        formData: {
                          ...prevState.formData,
                          ProductName: selectedValue, // Update formData with selected product ID
                        },
                      }));
                    }}
                  >
                    <option value="" disabled selected hidden>Select Product</option>
                    {productData && productData.map((item) => {
                      return (<option value={item.mediclaim_product_id} >{item.mediclaim_product_name}</option>)
                    })}
                  </select>
                  {formState?.errors?.ProductName && <div className="text-danger">{formState.errors.ProductName}</div>}
                </div>
                {productType && productData &&
                  productData
                    .filter((product) => product?.mediclaim_product_id == productType)
                    .map((product) => (
                      product.mediclaimproductpdfs.length > 0 ? (
                        product.mediclaimproductpdfs.map((pdf) => (
                          <div
                            key={pdf.mediclaim_pdf_id}
                            className="pdf-item"
                            style={{
                              border: "1px solid #ddd",
                              padding: "10px",
                              margin: "5px",
                              display: "inline-block",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "14px",
                                marginBottom: "5px",
                                fontWeight: "bold",
                              }}
                            >
                              {pdf.pdf_name}
                            </p>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                color: "blue",
                                textDecoration: "underline",
                                fontSize: "12px",
                              }}
                              onClick={(e) => {
                                e.preventDefault(); // Prevent default link behavior
                                redirectpageproduct(pdf.pdf_path); // Use the redirectpage function
                              }}
                            >
                              View PDF
                            </a>
                          </div>
                        ))
                      ) : (
                        <p key={product.mediclaim_product_id} style={{ fontStyle: "italic", color: "red" }}>
                          No PDFs available for this product.
                        </p>
                      )
                    ))}



                <div className='col-md-6 mb-4'>
                  <input
                    type="text"
                    name="CustomerID"
                    className='form-control'
                    placeholder="Customer ID"
                    value={formState.formData.CustomerID}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        CustomerID: e.target.value
                      }
                    }))}
                  />
                  {formState?.errors?.CustomerID && <div className="text-danger">{formState.errors.CustomerID}</div>}
                </div>
                <div className='col-md-6 mb-4'>
                  <label>Renew Date</label>
                  <input
                    type="date"
                    name="RenewDate"
                    className='form-control'
                    placeholder="Renew Date"
                    value={formState.formData.RenewDate}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        RenewDate: e.target.value
                      }
                    }))}
                  />
                  {formState?.errors?.RenewDate && <div className="text-danger">{formState.errors.RenewDate}</div>}
                </div>
              </div>
            </>
          )}
          {currentStep === 5 && (
            <>
              <div className='mediclaim-h1'>
                <h3>Agent Details</h3>
              </div>
              <div className='row'>
                <div className='col-md-6 mb-4'>
                  <input
                    type="text"
                    name="AgentName"
                    className='form-control'
                    placeholder="Agent Name"
                    value={formState.formData.AgentName}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        AgentName: e.target.value
                      }
                    }))}
                  />
                  {formState?.errors?.AgentName && <div className="text-danger">{formState.errors.AgentName}</div>}
                </div>
                <div className='col-md-6 mb-4'>
                  <input
                    type="text"
                    name="AgentContactNumber"
                    className='form-control'
                    placeholder="Agent Contact Number"
                    value={formState.formData.AgentContactNumber}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        AgentContactNumber: e.target.value
                      }
                    }))}
                  />
                  {formState?.errors?.AgentContactNumber && <div className="text-danger">{formState.errors.AgentContactNumber}</div>}
                </div>
                <div className='col-md-6 mb-4'>
                  <input
                    type="text"
                    name="AgentCode"
                    className='form-control'
                    placeholder="Agent Code"
                    value={formState.formData.AgentCode}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        AgentCode: e.target.value
                      }
                    }))}
                  />
                  {formState?.errors?.AgentCode && <div className="text-danger">{formState.errors.AgentCode}</div>}
                </div>
              </div>
            </>
          )}
          <div className="button-group d-flex justify-content-between">
            {currentStep > 1 && (
              <button className='btn btn-blue' type="button" onClick={(e) => { e.preventDefault(); handlePrev(); }}>Back</button>
            )}
            {currentStep < 5 ? (
              <button className='btn btn-blue' type="button" onClick={(e) => { e.preventDefault(); handleNext(); }}>Next</button>
            ) : (
              !view && currentStep === 5 && (
                <button type="submit" disabled={isSubmitting}>
                  {isSubmitting ? 'Submitting...' : 'Submit'}
                </button>
              )
            )}
          </div>
        </form>
      </div>
    </div>
  );
};


export default MediclaimPopup;
