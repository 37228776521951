import React, { useEffect, useState } from 'react';
import './Loan.css';
import DashboardLayout from '../components/DashboardLayout';
import { getAllConsumers, getAllLoanConsumer, getAllVerticle, getAllVerticleUser, updateLoanStatus } from '../serviceAPI/userAPI';
import StatusModal from '../components/Loan-status.popup';
import TableConsumerLoan from '../components/Loan-Table/Loan-Consumer';
import LoanConsumerPopup from '../components/Loan-consumer-popup';
import Cookies from 'js-cookie';

const Loan = () => {
  const [data, setData] = useState([]);
  const [verticle, setVerticle] = useState([]);
  const [verticleUser, setVerticleUser] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [heading, setHeading] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  const categoryId = Cookies.get('category');
  const user = (Cookies.get('user') && JSON.parse(Cookies.get('user'))) || '';

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
    setEditIndex(null);
  };

  const toggleEditPopup = () => {
    setIsEditPopupOpen(!isEditPopupOpen);
    setEditIndex(null);
  };

  useEffect(() => {
    getAllLoanConsumerData()
  }, []);

  const getAllLoanConsumerData = async () => {
    const consumerData = await getAllLoanConsumer();
    if (consumerData?.data && consumerData?.data?.length) {
      setData(consumerData?.data);
    } else {
      setData([]);
    }
    setHeading([{ key: 'userConsumers.username', head: 'Name' }, { key: 'userConsumers.email', head: 'Email' }, { key: 'userConsumers.mobileNumber', head: 'Mobile Number' }]);
  };

  const handleEdit = (index) => {
    setEditIndex(index);
    setIsPopupOpen(true);
  };
  

  const handleSort = (column) => {
    const direction = sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortColumn(column);
    setSortDirection(direction);
  };

  const fetchApi = () => {
    getAllLoanConsumerData();
  };

  return (
    <DashboardLayout>
      <div className='d-flex justify-content-between align-items-center dashboard-title'>
        <h1>Loan</h1>
        {((user && user.role_id !== 1) || (categoryId && categoryId.includes(2))) && <button className="btn btn-white" onClick={toggleEditPopup}>Add</button>}
      </div>
      {isPopupOpen && <StatusModal
        isOpen={isPopupOpen}
        onClose={togglePopup}
        fetchApi={fetchApi}
        initialData={editIndex !== null ? data[editIndex] : null}
      />}

      {isEditPopupOpen && (
        <LoanConsumerPopup
          isOpen={isEditPopupOpen}
          onClose={toggleEditPopup}
          fetchApi={fetchApi}
          initialData={null}
        />
      )}
      {(
        <TableConsumerLoan
          headings={heading}
          data={data}
          verticleOptions={verticle}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          itemsPerPage={itemsPerPage}
          handleEdit={handleEdit}
          handleSort={handleSort}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          setItemsPerPage={setItemsPerPage}
        />
      )}
    </DashboardLayout>
  );
};

export default Loan;
