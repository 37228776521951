import './Services.css';
import React, { useState } from "react";
import Breadcrumb from "../components/Breadcrumb";
import Clientslider from "../components/Clientslider.js";
import BlogHome from './BlogHome';
import Footer from "../components/Footer";

const Services = () => {
    
    return  (
      <>
      <div className='breadcrumbs'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <h3>Services</h3>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Services</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className='services'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-6'>
              <div className='services-heading'>
                <h1 className='heading'>Insurance services that have accompanied since 1995</h1>
                <p>For nearly three decades, we have been a reliable partner in protecting what matters most to individuals, families, and businesses. Established in 1995, our legacy is built on trust, commitment, and delivering exceptional insurance solutions tailored to your unique needs.</p>
                <h3 className='heading'>Our Journey of Excellence</h3>
                <p>Since our inception, we have been dedicated to providing comprehensive insurance services that evolve with your needs and the changing times. Over the years, we have expanded our offerings, refined our processes, and embraced innovation to ensure we deliver unparalleled value to our customers.</p>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='services-img'>
                <img src="./Assets/happy-family.jpg" className="img-fluid"/>
                <div className="experience">
                  <h4>25  Years of</h4>
                  <p>Experience</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="clients">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="heading text-center mb-4">Our Clients</h1>
              <Clientslider />
            </div>
          </div>
        </div>
      </section>
      <section className='service-info'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='service-info-heading'>
                <h1 className='heading text-center'>The focus of our insurance services</h1>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-3 col-md-6 my-3'>
              <div className='service-box'>
                <h3>Life Insurance</h3>
                <p>Life insurance provides financial security to your loved ones in the event of your passing. It ensures they are supported during challenging times, covering expenses such as daily living costs, education, debts, and more.</p>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 my-3'>
              <div className='service-box'>
                <h3>Loan Insurance</h3>
                <p>Loan insurance provides coverage to ensure that your outstanding loans are repaid in case of unforeseen events such as death, disability, or job loss. It protects you and your loved ones from the financial burden of unpaid debts.</p>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 my-3'>
              <div className='service-box'>
                <h3>Vehicle Insurance</h3>
                <p>Vehicle insurance provides financial protection for your car, bike, or any motor vehicle in case of accidents, theft, natural disasters, or third-party liabilities. It’s not just a legal requirement but also a smart way to safeguard your investment.</p>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 my-3'>
              <div className='service-box'>
                <h3>Mediclaim Insurance</h3>
                <p>Mediclaim insurance provides financial coverage for hospitalization, medical treatments, and related expenses. It ensures you can focus on recovery without worrying about the financial burden of medical bills.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="blog">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="blog-heading text-center">
              <a href="/blog" className="btn mb-4">Blog</a>
              <h1 className="heading">Recent Blogs</h1>
            </div>
          </div>
          <BlogHome />
        </div>
      </div>
    </section>
      <Footer />
      </>
    )    
};

export default Services;
