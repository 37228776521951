import React, { useEffect, useState } from 'react';
import './Loan.css';
import Table from '../components/Table';
import DashboardLayout from '../components/DashboardLayout';
import { getAllConsumers, getAllLoanConsumer, getAllLoanConsumerDetail, getAllLoanInterestedConsumer, getAllVerticle, getAllVerticleUser, updateLoanStatus } from '../serviceAPI/userAPI';
import { useToaster } from '../components/Toaster';
import LoanConsumerPopup from '../components/Loan-consumer-popup';
import Cookies from 'js-cookie';
import LoanInterestedTable from '../components/Loan-Table/Loan-Interested';
import PopupModal from '../components/View-Loan-Details.popup';


const Loani = () => {
  const addToast = useToaster()
  const categoryId = Cookies.get('category');
  const user = (Cookies.get('user') && JSON.parse(Cookies.get('user'))) || '';

  const getStartOfMonth = () => {
    const today = new Date();
    today.setDate(1); // Set to the first day of the current month
    return today.toISOString().split('T')[0]; // Format: YYYY-MM-DD
  };

  const getEndOfMonth = () => {
    const today = new Date();
    today.setMonth(today.getMonth() + 1); // Move to the next month
    today.setDate(0); // Get the last day of the current month
    return today.toISOString().split('T')[0]; // Format: YYYY-MM-DD
  };
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(getStartOfMonth());
  const [endDate, setEndDate] = useState(getEndOfMonth());
  const [detail, setDetail] = useState(null);
  const [verticle, setVerticle] = useState([]);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [isViewPopupOpen, setIsViewPopupOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [viewIndex, setViewIndex] = useState(null);
  const [heading, setHeading] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');



  useEffect(() => {
    getAllLoanConsumerData()
  }, []);

  const fetchApi = () => {
    getAllLoanConsumerData();
  };


  const getAllLoanConsumerData = async () => {
    const consumerData = await getAllLoanInterestedConsumer({ startDate, endDate });
    if (consumerData?.data && consumerData?.data?.length) {
      setData(consumerData?.data);
    } else {
      setData([]);
    }
    setHeading([{ key: 'userConsumers.username', head: 'Name' }, { key: 'userConsumers.mobileNumber', head: 'Mobile Number' }, { key: 'details.login_details.product', head: 'Product' }, { key: 'details.login_details.bank', head: 'Bank' }, { key: 'details.login_details.loanAmount', head: 'Loan Amount' }, { key: 'details.login_details.loanAccountNumber', head: 'Loan Account No.' }, { key: 'status', head: 'Status' }]);
  };


  const getLoanConsumerDetail = async (laon_id) => {
    const consumerData = await getAllLoanConsumerDetail({ laon_id }); // Send laon_id to get loan details
    if (consumerData?.data) {
      setDetail(consumerData?.data); // Set the detailed data to state
    }
  };


  const handleEdit = (index) => {
    setEditIndex(index);
    setIsEditPopupOpen(true);
  };

  const handleView = async (index) => {
    setViewIndex(index);
    const laon_id = data[index]?.details?.laon_id; // Get loan ID from selected row
    await getLoanConsumerDetail(laon_id); // Fetch detailed loan data for view
    setIsViewPopupOpen(true)
  };

  const toggleEditPopup = () => {
    setIsEditPopupOpen(!isEditPopupOpen);
    setEditIndex(null);
  };

  const toggleViewPopup = () => {
    setIsViewPopupOpen(!isViewPopupOpen);
    setViewIndex(null);
  };


  const handleSort = (column) => {
    const direction = sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortColumn(column);
    setSortDirection(direction);
  };

  const handleSearch = async () => {
    if (!startDate || !endDate) {
      alert("Please select both start and end dates.");
      return;
    }
    const consumerData = await getAllLoanInterestedConsumer({ startDate, endDate });
    if (consumerData?.data && consumerData?.data?.length) {
      setData(consumerData?.data);
    } else {
      setData([]);
    }
    setHeading([{ key: 'userConsumers.username', head: 'Name' }, { key: 'userConsumers.mobileNumber', head: 'Mobile Number' }, { key: 'details.login_details.product', head: 'Product' }, { key: 'details.login_details.bank', head: 'Bank' }, { key: 'details.login_details.loanAmount', head: 'Loan Amount' }, { key: 'details.login_details.loanAccountNumber', head: 'Loan Account No.' }, { key: 'status', head: 'Status' }]);
  };

  return (
    <DashboardLayout>
      <div className='d-flex justify-content-between align-items-center dashboard-title'>
        <h1>Loan</h1>
      </div>
      <div className="filter-section">
        <div className="filter-inputs">
          <div>
            <label>Start Date:</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="form-control"
            />
          </div>
          <div>
            <label>End Date:</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="form-control"
            />
          </div>
        </div>
        <button className="search-button" onClick={handleSearch}>Search</button>
      </div>
      {isEditPopupOpen && (
        <LoanConsumerPopup
          isOpen={isEditPopupOpen}
          onClose={toggleEditPopup}
          fetchApi={fetchApi}
          initialData={editIndex !== null ? data[editIndex] : null}
        />
      )}

      {isViewPopupOpen && (
        <PopupModal
          isOpen={isViewPopupOpen}
          onClose={toggleViewPopup}
          fetchApi={fetchApi}
          initialData={detail}
          mode="view" // Pass the mode prop as 'view' for viewing the loan details
        />
      )}
      {(
        <LoanInterestedTable
          headings={heading}
          data={data}
          verticleOptions={verticle}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          itemsPerPage={itemsPerPage}
          handleEdit={handleEdit}
          handleView={handleView}
          handleSort={handleSort}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          setItemsPerPage={setItemsPerPage}
        />
      )}
    </DashboardLayout>
  );
};

export default Loani;
