import React, { useState, useEffect } from 'react';
import './popup-u.css';
import { addConsumerUser, getAllBuilders, getAllVerticleUser, updateConsumerUser } from '../serviceAPI/userAPI';
import Cookies from 'js-cookie';
import { useToaster } from './Toaster';
import FlagDropdown from '../pages/Flag';

const ConsumerPopup = ({ isOpen, onClose, fetchApi, initialData }) => {
  const addToast = useToaster();
  const [formState, setFormState] = useState({
    formData: {
      Name: '',
      MobileNumber: '',
      Email: ''
    },
    checkboxes: {
      checkbox1: false,
      checkbox2: false,
      checkbox3: false,
      checkbox4: false,
    },
    dropdowns: {
      dropdown1: [],
      dropdown2: [],
      dropdown3: [],
      dropdown4: [],
    },
    dropdownValue: {
      dropdown1: '',
      dropdown2: '',
      dropdown3: '',
      dropdown4: '',
    },
    loading: {
      dropdown1: false,
      dropdown2: false,
      dropdown3: false,
      dropdown4: false,
    }
  });

  const [builderType, setBuilderType] = useState('');
  const [roleData, setRoleData] = useState([]);
  const user = (Cookies.get('user') && JSON.parse(Cookies.get('user'))) || '';

  useEffect(() => {
    const fetchData = async () => {
      if (initialData && initialData.user_id) {
        let checkboxes = {
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
          checkbox4: false,
        };
        let dropdownValue = {
          dropdown1: '',
          dropdown2: '',
          dropdown3: '',
          dropdown4: '',
        };

        if (initialData.category) {
          initialData.category.forEach(item => {
            switch (item.category_id) {
              case 2:
                checkboxes.checkbox1 = true;
                dropdownValue.dropdown1 = item.user_role_id;
                break;
              case 4:
                checkboxes.checkbox2 = true;
                dropdownValue.dropdown2 = item.user_role_id;
                break;
              case 5:
                checkboxes.checkbox3 = true;
                dropdownValue.dropdown3 = item.user_role_id;
                break;
              case 6:
                checkboxes.checkbox4 = true;
                dropdownValue.dropdown4 = item.user_role_id;
                break;
              default:
                break;
            }
          });
        }


        setFormState(prevState => ({
          ...prevState,
          formData: {
            Name: initialData.username,
            is_from_builder_user: initialData.is_from_builder_user,
            MobileNumber: initialData.mobileNumber,
            Email: initialData.email
          },
          checkboxes: checkboxes,
          dropdownValue: dropdownValue
        }));

        const fetchDropdowns = [];
        if (checkboxes.checkbox1) fetchDropdowns.push(fetchVerticleUserData(2, 1));
        if (checkboxes.checkbox2) fetchDropdowns.push(fetchVerticleUserData(4, 2));
        if (checkboxes.checkbox3) fetchDropdowns.push(fetchVerticleUserData(5, 3));
        if (checkboxes.checkbox4) fetchDropdowns.push(fetchVerticleUserData(6, 4));

        await Promise.all(fetchDropdowns);
      } else {
        setFormState(prevState => ({
          ...prevState,
          formData: {
            Name: '',
            is_from_builder_user: 0,
            MobileNumber: '',
            Email: ''
          }
        }));
      }
    }
    fetchData();
  }, [initialData]);

  useEffect(() => {
    getBuilderData();
  }, []);

  const getBuilderData = async () => {
    const roleData = await getAllBuilders();
    if (roleData?.data && roleData?.data?.length)
      setRoleData(roleData?.data);
  };


  const handleCheckboxChange = async (e) => {
    const { name, checked } = e.target;
    setFormState(prevState => ({
      ...prevState,
      checkboxes: {
        ...prevState.checkboxes,
        [name]: checked
      }
    }));


    const categoryId = parseInt(name.replace('checkbox', ''));
    const dropdownName = `dropdown${categoryId}`;

    if (checked && !formState.loading[dropdownName]) {
      setFormState(prevState => ({
        ...prevState,
        loading: {
          ...prevState.loading,
          [dropdownName]: true
        }
      }));
      let categoryIdDrop = null;
      switch (categoryId) {
        case 1:
          categoryIdDrop = 2; // Loan
          break;
        case 2:
          categoryIdDrop = 4; // Mediclaim
          break;
        case 3:
          categoryIdDrop = 5; // Life Insurance
          break;
        case 4:
          categoryIdDrop = 6; // Vehicle Insurance
          break;
        default:
          break;
      }
      await fetchVerticleUserData(categoryIdDrop, categoryId);
    }
  };

  const fetchVerticleUserData = async (categoryIdDrop, categoryId) => {
    try {
      const categoryVerticle = { category: [categoryIdDrop] };
      const verticleUserData = await getAllVerticleUser(categoryVerticle);
      if (verticleUserData?.data && verticleUserData?.data?.length) {
        // Map the API response to dropdown options
        const options = verticleUserData.data.map(item => ({
          value: item.user_id, // Adjust accordingly based on API response structure
          label: item.username // Adjust accordingly based on API response structure
        }));

        // Update dropdown options based on categoryId

        setFormState(prevState => ({
          ...prevState,
          dropdowns: {
            ...prevState.dropdowns,
            [`dropdown${categoryId}`]: options
          }
        }));
      }
    } catch (error) {
      console.error('Error fetching verticle user data:', error);
    } finally {
      setFormState(prevState => ({
        ...prevState,
        loading: {
          ...prevState.loading,
          [`dropdown${categoryId}`]: false
        }
      }));
    }
  };

  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value)
    console.log(formState)
    setFormState(prevState => ({
      ...prevState,
      dropdownValue: {
        ...prevState.dropdownValue,
        [name]: value
      }
    }));
  };
  console.log(formState)
  const handleSubmit = async (e) => {
    e.preventDefault();

    const regex = /^\d{10}$/;
    if (!regex.test(formState.formData.MobileNumber)) {
      alert('Mobile number is invalid');
      return;
    }

    const selectedCategories = [];
    console.log(formState.checkboxes)

    if (formState.checkboxes.checkbox1 && formState.dropdownValue?.dropdown1) {
      selectedCategories.push({ category_id: 2, user_role_id: formState.dropdownValue?.dropdown1 });
    }
    if (formState.checkboxes.checkbox2 && formState.dropdownValue?.dropdown2) {
      selectedCategories.push({ category_id: 4, user_role_id: formState.dropdownValue?.dropdown2 });
    }
    if (formState.checkboxes.checkbox3 && formState.dropdownValue?.dropdown3) {
      selectedCategories.push({ category_id: 5, user_role_id: formState.dropdownValue?.dropdown3 });
    }

    if (formState.checkboxes.checkbox4 && formState.dropdownValue?.dropdown4) {
      selectedCategories.push({ category_id: 6, user_role_id: formState.dropdownValue?.dropdown4 });
    }

    const userData = {
      username: formState.formData.Name.trim(),
      phone_number: formState.formData.MobileNumber,
      email: formState.formData.Email,
      category: selectedCategories,
      is_from_builder_user: formState?.formData?.is_from_builder_user || 0
    };

    if (user && user.role_id === 1) {
      userData.builderType = builderType;
    }

    if (initialData && initialData.user_id) {
      userData.user_id = initialData.user_id;
      const response = await updateConsumerUser(userData, addToast);
      if (response.status) {
        fetchApi();
        onClose();
      } else {
        // onClose();
      }
    } else {
      const response = await addConsumerUser(userData, addToast);
      if (response.status) {
        fetchApi();
        onClose();
      } else {
        // onClose();
      }
    }
  };

  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <div className='popup-header d-flex justify-content-between align-items-center'>
          <h2>{initialData ? 'Edit Consumer' : 'Add Consumer'}</h2>
          <span className="close-btn" onClick={onClose}>&times;</span>
        </div>
        <form className="popup-form" onSubmit={handleSubmit}>
          <div className='row'>
            <div className='col-md-6 mb-4'>
              <label className="form-label">Name</label>
              <input
                type="text"
                name="Name"
                className='form-control'
                placeholder="Name"
                value={formState.formData.Name}
                onChange={(e) => setFormState(prevState => ({
                  ...prevState,
                  formData: {
                    ...prevState.formData,
                    Name: e.target.value
                  }
                }))}
              />
            </div>
            <div className='col-md-6 mb-4'>
              <label className="form-label">Email</label>
              <input
                type="email"
                name="Email"
                className='form-control'
                placeholder="Email"
                value={formState.formData.Email}
                onChange={(e) => setFormState(prevState => ({
                  ...prevState,
                  formData: {
                    ...prevState.formData,
                    Email: e.target.value
                  }
                }))}
              />
            </div>
            <div className='col-md-6 mb-4'>
              <label className="form-label">Mobile Number</label>
              <div style={{ display: 'flex' }}>
                <FlagDropdown />
                <input
                  type="text"
                  name="MobileNumber"
                  className='form-control'
                  style={{ margin: '0px' }}
                  placeholder="Mobile Number"
                  value={formState.formData.MobileNumber}
                  onChange={(e) => setFormState(prevState => ({
                    ...prevState,
                    formData: {
                      ...prevState.formData,
                      MobileNumber: e.target.value
                    }
                  }))}
                />
              </div>

            </div>
            {!initialData && user && user.role_id === 1 &&
              <div className='col-md-6 mb-4'>
                <label className="form-label">Select Builder</label>
                <select value={builderType} className='form-select' onChange={(e) => setBuilderType(e.target.value)}>
                  <option value="" disabled selected hidden>Select Builder</option>
                  {roleData.map(item => (
                    <option key={item.user_id} value={item.user_id}>{item.username}</option>
                  ))}
                </select>
              </div>
            }
            {user && user.role_id === 1 &&
              <div className='col-md-12 mb-4'>
                <label className="form-label">Assign Category</label>
                {Object.keys(formState.checkboxes).map((checkboxName, index) => (
                  <div key={checkboxName} className='checkbox-list mb-4'>
                    <div className='d-flex align-items-center'>
                      <input type="checkbox" name={checkboxName} className='me-2' checked={formState.checkboxes[checkboxName]} onChange={handleCheckboxChange} />
                      <label>{index === 0 ? 'Loan' : index === 1 ? 'Mediclaim' : index === 2 ? 'Life Insurance' : 'Vehicle Insurance'}</label>
                      <select
                        name={`dropdown${index + 1}`}
                        value={formState.dropdownValue[`dropdown${index + 1}`]}
                        className='form-select'
                        onChange={handleDropdownChange}
                        disabled={!formState.checkboxes[checkboxName] || formState.loading[`dropdown${index + 1}`]}
                      >
                        <option value="">Select option {index + 1}</option>
                        {formState.dropdowns[`dropdown${index + 1}`].map(option => (
                          <option key={option.value} value={option.value}>{option.label}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                ))}
              </div>
            }
          </div>
          <button className="btn btn-blue" type="submit">{initialData ? 'Update' : 'Add'}</button>
        </form>
      </div>
    </div>
  );
};

export default ConsumerPopup;
