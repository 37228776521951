import React, { useState } from 'react';
import './Contact.css';
import Breadcrumb from '../components/Breadcrumb.js';
import Footer from '../components/Footer';
import { addInquieryUser } from '../serviceAPI/userAPI.js';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        service: ''
    });
    const [errors, setErrors] = useState({});


    const handleChange = (e) => {
        const { name, value } = e.target;
        const newErrors = { ...errors }; // Copy existing errors

        // Update form data
        setFormData({ ...formData, [name]: value });

        // Dynamic validation for each field
        if (name === 'name') {
            if (!value.trim()) {
                newErrors.name = 'Name is required.';
            } else {
                delete newErrors.name; // Remove error if valid
            }
        }

        if (name === 'email') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!value.trim() || !emailRegex.test(value)) {
                newErrors.email = 'A valid email is required.';
            } else {
                delete newErrors.email; // Remove error if valid
            }
        }

        if (name === 'phone') {
            if (!value.trim() || value.length !== 10 || isNaN(value)) {
                newErrors.phone = 'Phone number must be exactly 10 digits.';
            } else {
                delete newErrors.phone; // Remove error if valid
            }
        }

        if (name === 'service') {
            if (!value) {
                newErrors.service = 'Please select a service.';
            } else {
                delete newErrors.service; // Remove error if valid
            }
        }

        // Update errors state
        setErrors(newErrors);
    };


    // Form validation
    const validate = () => {
        const newErrors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!formData.name.trim()) newErrors.name = 'Name is required.';
        if (!formData.email.trim() || !emailRegex.test(formData.email)) newErrors.email = 'A valid email is required.';
        if (!formData.phone.trim() || formData.phone.length !== 10 || isNaN(formData.phone))
            newErrors.phone = 'Phone number must be exactly 10 digits.';
        if (!formData.service) newErrors.service = 'Please select a service.';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            console.log('Form submitted:', formData);
            // Replace this with your API call
            // alert('Form submitted successfully!');
            const userData = {
                username: formData.name.trim(),
                phone_number: formData.phone,
                email: formData.email,
                service: formData.service
            };

            const response = await addInquieryUser(userData);
            if (response.status) {
                setFormData({ name: '', email: '', phone: '', service: '' }); // Reset form
            }
        }
    };

    return (
        <>
            <section className='breadcrumbs'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <h3>Contact Us</h3>
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">Contact Us</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            <div className='contact'>
                <div className='map'>
                    <div className='container'>
                        <di className='row'>
                            <div className='col-12'>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.838528738882!2d144.9532000758856!3d-37.81725097197468!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65d4dd5a05d97%3A0x3e64f855a564844d!2s121%20King%20St%2C%20Melbourne%20VIC%203000%2C%20Australia!5e0!3m2!1sen!2sin!4v1723640702555!5m2!1sen!2sin"
                                    width="100%" height="450"
                                    allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </di>
                    </div>
                </div>
                <div className='form'>
                    <div className='container'>
                        <div className='row align-items-center'>
                            <div className='col-lg-7'>
                                <form onSubmit={handleSubmit} className="contact-form">
                                    <div className="mb-3">
                                        <label for="name" className="form-label">Your Name</label>
                                        <input type="text"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder="Name" />
                                        {errors.name && <small className="text-error">{errors.name}</small>}
                                    </div>
                                    <div className="mb-3">
                                        <label for="email" className="form-label">Your Email</label>
                                        <input type="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder="Email" />
                                        {errors.email && <small className="text-error">{errors.email}</small>}
                                    </div>
                                    <div className="mb-3">
                                        <label for="phone" className="form-label">Your Phone Number</label>
                                        <input type="tel"
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder="Phone Number"
                                            maxLength="10" />
                                        {errors.phone && <small className="text-error">{errors.phone}</small>}
                                    </div>
                                    <div className="mb-3">
                                        <label for="service" className="form-label">Services</label>
                                        <select name="service"
                                            value={formData.service}
                                            onChange={handleChange}
                                            className="form-select">
                                            <option value="" disabled>Services</option>
                                            <option value="Loan Insurance">Loan Insurance</option>
                                            <option value="Life Insurance">Life Insurance</option>
                                            <option value="Vehicle Insurance">Vehicle Insurance</option>
                                            <option value="Mediclaim Insurance">Mediclaim Insurance</option>
                                        </select>
                                        {errors.service && <small className="text-error">{errors.service}</small>}
                                    </div>
                                    <button type="submit" className="btn btn-white">Submit</button>
                                </form>
                            </div>
                            <div className='col-lg-5'>
                                <div className="contact-info-section">
                                    <h1 className='heading'>Feel free to contact us if you have a question</h1>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                    <a className='phone' href='#'>
                                        <i class="bi bi-telephone"></i>
                                        <span>+91 99257 12341</span>
                                    </a>
                                    <a className='email' href='#'>
                                        <i class="bi bi-envelope"></i>
                                        <span>info@nanakfinserv.com</span>
                                    </a>
                                    <a className='address' href='#'>
                                        <i class="bi bi-geo-alt"></i>
                                        <span>Rajkot, India</span>
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

const ContactInfoBox = ({ imageSrc, title, info }) => (
    <div className="contact-col-box">
        <div className="contact-box">
            <figure><img src={imageSrc} alt="" className="img-fluid mission-icons" /></figure>
            <h5 className="mission-counter-contact">{title}:</h5>
            <p className="mb-0 contact-us-box-p">{info}</p>
        </div>
    </div>
);

const CheckboxInput = ({ name, label, onChange }) => (
    <label>
        <input type="checkbox" name={name} onChange={onChange} />
        {label}
    </label>
);

export default Contact;
