import React, { useState } from 'react';
import './HamburgerMenu.css'; 

const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav role="navigation" className='mobileMenu' style={{background:'black'}}>
      <div id="menuToggle">
        <input type="checkbox" checked={isOpen} onChange={toggleMenu} />
        <span className={isOpen ? 'open' : ''}></span>
        <span className={isOpen ? 'open' : ''}></span>
        <span className={isOpen ? 'open' : ''}></span>
        <ul id="menu" className={isOpen ? 'show' : ''}>
          <a href="/"><li>Home</li></a>
          <a href="/Service"><li>Service</li></a>
          <a href="/About"><li>About</li></a>
          <a href="/Contact"><li>Contact</li></a>
          <a href="/Blog"><li>Blog</li></a>
          <button className="btn" type="submit">Login</button>
        </ul>        
      </div>      
    </nav>    
  );
};

export default HamburgerMenu;
