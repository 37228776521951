import React, { useState, useEffect } from "react";
import Footer from "../components/Footer.js";
import { FlipWords } from "../components/flip-words.tsx";
import Slider from "../components/Slider.js";
import Popup from "../components/popup.js";
import "./HomePage.css";
import Review from '../components/Review.js';
import BlogHome from "./BlogHome.js";
import Clientslider from "../components/Clientslider.js";
import Guidequestions from "../components/Guidequestions.js";
import Testimonialslider from "../components/Testimonialslider.js";
import FAQ from "../components/faq.js";


const HomePage = () => {
  const [items, setItems] = useState([]);
  // const [faqs, setFaqs] = useState([
  //   {
  //     question: "How many programmers does it take to screw a lightbulb?",
  //     answer:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pharetra lorem eu dolor rhoncus, at scelerisque ligula gravida. Sed porta id mi sit amet convallis. Etiam iaculis massa sit amet lacus blandit sodales. Nulla ultrices velit a diam placerat congue. Pellentesque iaculis, ipsum quis eleifend dapibus, est dui eleifend ante, quis fermentum mi ligula quis nisl. Ut et ex dui. Integer id venenatis quam.",
  //     open: true,
  //   },
  //   {
  //     question: "Who is the most awesome person?",
  //     answer: "You! The viewer!",
  //     open: false,
  //   },
  //   {
  //     question:
  //       "How many questions does it take to makes a succesful FAQ Page?",
  //     answer: "This many!",
  //     open: false,
  //   },
  // ]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };
  
  // const toggleFAQ = (index) => {
  //   setFaqs(
  //     faqs.map((faq, i) => {
  //       if (i === index) {
  //         faq.open = !faq.open;
  //       } else {
  //         faq.open = false;
  //       }

  //       return faq;
  //     })
  //   );
  // };

  return (
    <>
    <section className="hero-banner">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="hero-banner-content">
              <h1 className="heading">Welcome to Nanak Finserv – Your Trusted Financial Consultancy in Rajkot, Gujarat</h1>
              <p>At Nanak Finserv, we specialize in offering comprehensive financial solutions tailored to your needs. From securing the best home loans to protecting your future with insurance plans, we are your one-stop solution for financial guidance and consultancy..</p>
              <a href="/services" className="btn">Explore More</a>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="hero-banner-img">
              <img src="./Assets/hero-banner-img.jpg" alt="Hero Section" className="hero-banner-img img-fluid"/>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="features">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="features-box">
              <img src="./Assets/customers-world-wide.jpg" className="img-fluid" />
              <div className="overlay"></div>
              <h5>We handling customers all over India</h5>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="features-box">
              <img src="./Assets/customers-world-wide.jpg" className="img-fluid" />
              <div className="overlay"></div>
              <h5>Instant Claims Processing</h5>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="features-box">
              <img src="./Assets/customers-world-wide.jpg" className="img-fluid" />
              <div className="overlay"></div>
              <h5>Trusted since<br/>1995 until present</h5>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="about">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="about-content">
              <a href="/about" className="btn mb-4">About us</a>
              <h1 className="heading">We provide the best solutions to guarantee the future for you or your family!</h1>
              <p>At Nanak Finserv, our mission is to secure your future with insurance solutions that offer complete protection for you and your loved ones. From health and life insurance to coverage for your home and vehicle, our tailored plans ensure that you’re prepared for whatever life brings.</p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="about-right-content">
              <p>We understand that every individual and family has unique needs, which is why we take the time to customize policies that fit your lifestyle and budget. With our trusted support, you can face the future with confidence, knowing that you’re covered by the best in the business.</p>
              <p>Let us help you safeguard what matters most—your family, your home, and your peace of mind.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="service">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="service-heading">
              <h1 className="heading text-center">Why choose our services for your guarantee insurance?</h1>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="service-img">
              <img src="./Assets/are-you-covered.jpg" className="img-fluid"/>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="service-points">
              <div className="point">
                <div className="point-content">
                  <h4>Comprehensive Coverage Options</h4>
                  <p>We offer a wide range of insurance plans to meet your specific needs, including health, life, auto, home, and business coverage. Our flexible policies ensure that you get the protection you need without paying for what you don’t.</p>
                </div>
              </div>
              <div className="point">
                <div className="point-content">
                  <h4>24/7 Customer Support</h4>
                  <p>We're here for you when you need us most. Our dedicated support team is available around the clock to assist with claims, policy changes, and any questions you may have.</p>
                </div>
              </div>
              <div className="point">
                <div className="point-content">
                  <h4>Affordable and Transparent Pricing</h4>
                  <p>Enjoy peace of mind knowing that you’re getting high-quality coverage at competitive rates. We believe in transparent pricing with no hidden fees, so you know exactly what you're paying for.</p>
                </div>
              </div>
              <div className="point">
                <div className="point-content">
                  <h4>Fast and Easy Claims Process</h4>
                  <p>We make it simple to file and manage claims, ensuring a hassle-free experience when you need to use your insurance. Our goal is to get you back on track as quickly as possible.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="clients">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h1 className="heading text-center mb-4">Our Clients</h1>
            <Clientslider />
          </div>
        </div>
      </div>
    </section>
    <section className="contact-info">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="contact-info-content">
              <h1 className="heading">Free your youth for a brighter and more purposeful future</h1>
              <p>At Nanak Finserv, we believe in providing young people with the freedom and security to pursue their passions, knowing that their future is protected. Our comprehensive insurance plans are designed to safeguard their health, education, and financial well-being, allowing them to focus on what truly matters—building a fulfilling and impactful life.</p>
              <div className="img">
                <img src="./Assets/happy-family.jpg" className="img-fluid"/>
                <div className="experience">
                  <h4>25  Years of</h4>
                  <p>Experience</p>
                </div> 
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="contact-info-details">
              <img src="./Assets/couple-taking-insurance.jpg" className="img-fluid"/>
              <div className="row">
                <div className="col-6">
                  <div className="certifed-team">
                    <div className="certifed-team-content">
                      <h4>Certifed Team</h4>
                      <p>Trust our expertise to protect what matters most to you, and experience the peace of mind that comes with working with true insurance professionals.</p>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="trusted-company">
                    <div className="trusted-company-content">
                      <h4>Trusted Company</h4>
                      <p>We prioritize transparency in all our dealings, offering clear policies and straightforward advice so you always know what to expect.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="phone-number">
                    <div className="phone-number-info">
                      <h1 className="heading">+91 99257 12341</h1>
                      <p>Call Agent</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="blog">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="blog-heading text-center">
              <a href="/blog" className="btn mb-4">Blog</a>
              <h1 className="heading">Recent Blogs</h1>
            </div>
          </div>
          <BlogHome />
        </div>
      </div>
    </section>
    <Footer /> 
    </>    
    
  );
};

export default HomePage;
