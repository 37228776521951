import React, { useState, useEffect } from 'react';
import './popup-u.css';
import { addRoleUser, getCategoryById, getRoles, updateRoleUser } from '../serviceAPI/userAPI';
import { useToaster } from './Toaster';
import FlagDropdown from '../pages/Flag';

const Popup = ({ isOpen, onClose, fetchApi, initialData }) => {
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [userTypes, setUserTypes] = useState([]);
  const [message, setMessage] = useState('');
  const [roleData, setRoleData] = useState([]);
  const addToast = useToaster()

  useEffect(() => {
    console.log(initialData)
    if (initialData) {
      setName(initialData.username);
      setMobile(initialData.mobileNumber); // Updated key to match backend
      setEmail(initialData.email);
      const initialUserTypes = Array.isArray(initialData.categories)
        ? initialData.categories
        : (initialData.categories || '').split(',').map(Number); // Handle comma-separated string
      setUserTypes(initialUserTypes);
      getCatergoryId()
    } else {
      setName('');
      setMobile(''); // Updated key to match backend
      setEmail('');
      setUserTypes([]);
    }
  }, [initialData]);

  useEffect(() => {
    getRoleData()
  }, []);

  const getCatergoryId = async () => {
    const Data = await getCategoryById(initialData?.user_id);
  }

  const getRoleData = async () => {
    const roleData = await getRoles();
    if (roleData?.data && roleData?.data?.length)
      setRoleData(roleData?.data)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const regex = /^\d{10}$/;
    if (!regex.test(mobile)) {
      alert('mobile number invalid')
      return
    }

    const userData = {
      username: name.trim(),
      phone_number: mobile,
      email,
      role: userTypes.includes(1) ? 1 : 4, // Take the first value (or null if empty)
      roleId: userTypes.join(','), // Always save as a comma-separated string
    };


    if (initialData && initialData.user_id) {
      userData.user_id = initialData.user_id
      const response = await updateRoleUser(userData, addToast);
      if (response.status) {
        setMessage('User registered successfully!');
        fetchApi();
        onClose();
      } else {
        onClose();
      }
    } else {
      const response = await addRoleUser(userData, addToast);
      if (response.status) {
        setMessage('User registered successfully!');
        fetchApi();
        onClose();
      } else {
        onClose();
      }
    }


  };

  const handleChange = (e) => {
    const options = e.target.options;
    const selectedValues = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedValues.push(parseInt(options[i].value));
      }
    }
    setUserTypes(selectedValues);
  };
  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <div className='popup-header d-flex justify-content-between'>
          <h2>{initialData ? 'Edit User' : 'Add User'}</h2>
          <span className="close-btn" onClick={onClose}>&times;</span>
        </div>
        <form className="popup-form" onSubmit={handleSubmit}>
          <div className='row'>
            <div className='col-md-6 mb-4'>
              <label className="form-label">Name</label>
              <input type="text" placeholder='Name' className='form-control' value={name} onChange={(e) => setName(e.target.value)} required />
            </div>
            <div className='col-md-6 mb-4'>
              <label className="form-label">Email</label>
              <input type="email" placeholder='Email' className='form-control' value={email} onChange={(e) => setEmail(e.target.value)} required />
            </div>
            <div className='col-md-6 mb-4'>
              <label className="form-label">Mobile Number</label>
              <div style={{ display: 'flex' }}>
                <FlagDropdown />
                <input type="text" style={{margin:'0'}} placeholder='Mobile Number' className='form-control' value={mobile} onChange={(e) => setMobile(e.target.value)} required />
              </div>

            </div>
            <div className='col-md-6 mb-4'>
              <label className="form-label">Select Roles</label>
              <select
                id="userRoles"
                name="roles"
                multiple
                value={userTypes} // value must be an array for multiple selections
                onChange={handleChange}
                className="form-select select-multipl"
              >
                <option value="" disabled hidden>
                  Select Role
                </option>
                {roleData &&
                  roleData.map((item) => (
                    <option key={item.category_id} value={item.category_id}>
                      {item.category_name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <button className='btn btn-blue' type="submit">{initialData ? 'Update' : 'Add'}</button>
        </form>
        {message && <p>{message}</p>}
      </div>
    </div>
  );
};

export default Popup;
