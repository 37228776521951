import React, { useState, useEffect } from "react";
import "../Table.css";
import Search from "../Search";

const LoanInterestedTable = ({
  headings,
  data,
  handleEdit,
  handleView,
  currentPage,
  setCurrentPage,
  itemsPerPage,
  sortColumn,
  sortDirection,
  handleSort,
  setItemsPerPage,
}) => {
  const [filteredData, setFilteredData] = useState(data);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(""); // Changed to string

  const getStatusClass = (status) => {
    switch (status) {
      case "documentselected":
        return "status-documentselected";
      case "pickup":
        return "status-pickup";
      case "login":
        return "status-login";
      case "query":
        return "status-query";
      case "sanction":
        return "status-sanction";
      case "disbursement":
        return "status-disburse";
      case "cancel":
        return "status-cancel";
      case "partPayment":
        return "status-partPayment";
      default:
        return "status-default";
    }
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case "documentselected":
        return "Document Selected";
      case "pickup":
        return "Pickup";
      case "login":
        return "Login";
      case "query":
        return "Query";
      case "sanction":
        return "Sanction";
      case "disbursement":
        return "Disbursement";
      case "cancel":
        return "Cancel";
      case "partPayment":
        return "Part Payment";
      case "interested":
        return "No Status";
      default:
        return "No Status";
    }
  };

  useEffect(() => {
    filterData(searchTerm, selectedStatus);
  }, [data, searchTerm, selectedStatus]);

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    setCurrentPage(1);
  };

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
    setCurrentPage(1); // Reset page to 1 when the status changes
  };

  const filterData = (searchTerm, status) => {
    const newData = data.filter((item) => {
      const matchesSearch = Object.values(item).some((val) =>
        String(val).toLowerCase().includes(searchTerm)
      );
      const matchesStatus = status ? item.details?.status === status : true;
      return matchesSearch && matchesStatus;
    });
    setFilteredData(newData);
  };

  const clearSearch = () => {
    setSearchTerm("");
    setFilteredData(data);
  };

  const sortedData = [...filteredData].sort((a, b) => {
    if (sortColumn) {
      const aValue = String(a[sortColumn]).toLowerCase();
      const bValue = String(b[sortColumn]).toLowerCase();
      if (aValue < bValue) return sortDirection === "asc" ? -1 : 1;
      if (aValue > bValue) return sortDirection === "asc" ? 1 : -1;
    }
    return 0;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = sortedData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handleClick = (event) => {
    setCurrentPage(Number(event.target.id));
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getVisiblePageNumbers = () => {
    const pages = [];
    let startPage, endPage;

    if (totalPages <= 3) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 2) {
        startPage = 1;
        endPage = 3;
      } else if (currentPage >= totalPages - 1) {
        startPage = totalPages - 2;
        endPage = totalPages;
      } else {
        startPage = currentPage - 1;
        endPage = currentPage + 1;
      }
    }

    if (startPage > 1) {
      pages.push(
        <button
          key={1}
          id={1}
          onClick={handleClick}
          className={`page-number ${currentPage === 1 ? "active" : ""}`}
        >
          1
        </button>
      );
      if (startPage > 2) {
        pages.push(
          <span key="start-ellipsis" className="ellipsis">
            ...
          </span>
        );
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          id={i}
          onClick={handleClick}
          className={`page-number ${currentPage === i ? "active" : ""}`}
        >
          {i}
        </button>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pages.push(
          <span key="end-ellipsis" className="ellipsis">
            ...
          </span>
        );
      }
      pages.push(
        <button
          key={totalPages}
          id={totalPages}
          onClick={handleClick}
          className={`page-number ${currentPage === totalPages ? "active" : ""
            }`}
        >
          {totalPages}
        </button>
      );
    }

    return pages;
  };

  return (
    <div>
      <div className="table-container">
        <div className="table-h">
          <div className="table-h-c">
            <Search
              searchTerm={searchTerm}
              handleSearch={handleSearch}
              clearSearch={clearSearch}
            />
          </div>
          <div className="table-h-c">
            <select
              value={selectedStatus}
              onChange={handleStatusChange}
              className="status-filter"
            >
              <option value="">All Status</option>
              <option value="documentselected">Document Selected</option>
              <option value="pickup">Pickup</option>
              <option value="login">Login</option>
              <option value="query">Query</option>
              <option value="sanction">Sanction</option>
              <option value="disbursement">Disbursement</option>
              <option value="cancel">Cancel</option>
              <option value="partPayment">Part Payment</option>
              <option value="interested">No Status</option>
            </select>
          </div>
          <div className="table-h-c"></div>
          <div className="show-by table-h-c">
            <label>Show by Rows:</label>
            <select
              value={itemsPerPage}
              onChange={(e) => {
                setItemsPerPage(parseInt(e.target.value));
                setCurrentPage(1);
              }}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
        </div>
        <table className="universal-table">
          <thead>
            <tr>
              <th>S.No</th>
              {headings.map((heading, index) => (
                <th key={index} onClick={() => handleSort(heading.key)}>
                  {heading.head}{" "}
                  {sortColumn === heading.key
                    ? sortDirection === "asc"
                      ? "▲"
                      : "▼"
                    : null}
                </th>
              ))}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentData && currentData.length ? (
              currentData.map((item, index) => {
                const loanStatus = item?.details?.status || "";
                const loanAmount = item?.details?.login_details?.loanAmount
                const loanAccount = item?.details?.login_details?.loanAccountNumber
                const product = item?.details?.login_details?.product
                const bank = item?.details?.login_details?.bankName
                return (
                  <tr key={index}>
                    <td>{indexOfFirstItem + index + 1}</td>
                    {headings.map((heading, hIndex) => (
                      <td className="show-by" key={hIndex}>
                        {heading.key === "status" ? (
                          <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                            <span className={`status-box ${getStatusClass(loanStatus)}`}>
                              {getStatusLabel(loanStatus) || "No Status"}
                            </span>
                          </div>
                        ) : heading.key === "details.login_details.loanAccountNumber" ? loanAccount : heading.key === "details.login_details.loanAmount" ? loanAmount : heading.key === "details.login_details.product" ? product : heading.key === "details.login_details.bank" ? bank : (
                          item[heading.key]
                        )}
                      </td>
                    ))}
                    <td>
                      <div style={{ padding: "0px", display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                        <img
                          className="table-edit"
                          src="/Assets/edit-icon.png"
                          alt="edit"
                          onClick={() => handleEdit(indexOfFirstItem + index)}
                        />
                        <img
                          className="table-view"
                          src="/Assets/view-icon.png"
                          alt="view"
                          onClick={() => handleView(indexOfFirstItem + index)} // Trigger view
                        />
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={headings.length + 2}>No record found</td>
              </tr>
            )}
          </tbody>
        </table>
        {filteredData.length > 0 && (
          <div className="pagination">
            <button onClick={handlePreviousPage} disabled={currentPage === 1}>
              Previous
            </button>
            {getVisiblePageNumbers()}
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoanInterestedTable;
