import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Sidebar.css';
import Cookies from 'js-cookie';

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const [activeTab, setActiveTab] = useState('');
  const [openSubmenus, setOpenSubmenus] = useState(() => {
    // Retrieve submenu state from localStorage on initial load
    const storedState = localStorage.getItem('openSubmenus');
    return storedState ? JSON.parse(storedState) : {};
  });

  const location = useLocation();

  useEffect(() => {
    setActiveTab(location.pathname);

    // Automatically open submenu based on the current route
    const activePath = location.pathname.split('/')[1]; // e.g., 'builder', 'loan', etc.
    if (activePath) {
      setOpenSubmenus((prevState) => ({
        ...prevState,
        [activePath]: true, // Open the submenu based on path
      }));
    }
  }, [location.pathname]);


  const categoryId = Cookies.get('category');
  const user = (Cookies.get('user') && JSON.parse(Cookies.get('user'))) || '';

  const handleLinkClick = () => {
    toggleSidebar();
  };

  const handleLogout = () => {
    Cookies.remove('user');
    Cookies.remove('token');
    window.location.reload();
  };

  const toggleSubmenu = (submenuKey) => {
    const updatedSubmenus = {
      ...openSubmenus,
      [submenuKey]: !openSubmenus[submenuKey], // Toggle the state of the selected submenu
    };

    setOpenSubmenus(updatedSubmenus);

    // Save updated submenu state to localStorage
    localStorage.setItem('openSubmenus', JSON.stringify(updatedSubmenus));
  };

  const isUnitRoute = location?.pathname?.startsWith('/unit/');

  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`} id="sidebar">
      <div className="sidebar-header">
        <img className="sidebar-logo cropped-logo" src="../Assets/cropped-logo.png" alt="Logo" />
        <img className="sidebar-logo text-logo" src="../Assets/text-logo.png" alt="Logo" />
      </div>

      <ul className="menu">
        {user && user.role_id !== 2 && <li className={`${activeTab === '/dashboard' && 'active'}`}>
          <Link to="/dashboard" onClick={handleLinkClick}>
            <i className="bi bi-speedometer2"></i>
            <span>Dashboard</span>
          </Link>
        </li>}

        {(user && (user.role_id === 1 || user.role_id === 2)) && <li className={`${activeTab === '/consumer' && 'active'}`}>
          <Link to="/consumer" onClick={handleLinkClick}>
            <i className="bi bi-person"></i>
            <span>Consumer</span>
          </Link>
        </li>}

        {(user && (user.role_id === 1 || user.role_id === 2)) && (
          <li>
            <div
              className={`submenu-toggle ${openSubmenus['builder'] ? 'open' : ''}`}
              onClick={() => toggleSubmenu('builder')}
            >
              <i className="bi bi-buildings"></i>
              <span>Builder</span>
              <i className={`submenu-arrow bi ${openSubmenus['builder'] ? 'bi-chevron-up' : 'bi-chevron-down'}`}></i>
            </div>
            <ul className={`submenu ${openSubmenus['builder'] ? 'open' : ''}`}>
              <li className={`${activeTab === '/builder' && 'active'}`}>
                <Link to="/builder" onClick={handleLinkClick}>
                  <span>Builder</span>
                </Link>
              </li>
            </ul>
            <ul className={`submenu ${openSubmenus['builder'] ? 'open' : ''}`}>
              <li className={`${(activeTab === '/builder/building' || location.pathname.startsWith('/unit/')) && 'active'}`}>
                <Link to="/builder/building" onClick={handleLinkClick}>
                  <span>Building</span>
                </Link>
              </li>
            </ul>
          </li>
        )}

        {((user && user.role_id === 1) || (categoryId && categoryId.includes(2))) && (
          <li>
            <div
              className={`submenu-toggle ${openSubmenus['loan'] ? 'open' : ''}`}
              onClick={() => toggleSubmenu('loan')}
            >
              <i className="bi bi-cash-coin"></i>
              <span>Loan</span>
              <i className={`submenu-arrow bi ${openSubmenus['loan'] ? 'bi-chevron-up' : 'bi-chevron-down'}`}></i>
            </div>
            <ul className={`submenu ${openSubmenus['loan'] ? 'open' : ''}`}>
              <li className={`${activeTab === '/loan' && 'active'}`}>
                <Link to="/loan" onClick={handleLinkClick}>
                  Consumer
                </Link>
              </li>
              <li className={`${activeTab === '/loan/interested' && 'active'}`}>
                <Link to="/loan/interested" onClick={handleLinkClick}>
                  Interested
                </Link>
              </li>
              <li className={`${activeTab === '/loan/completed' && 'active'}`}>
                <Link to="/loan/completed" onClick={handleLinkClick}>
                  Completed
                </Link>
              </li>
              <li className={`${activeTab === '/loan/not-interested' && 'active'}`}>
                <Link to="/loan/not-interested" onClick={handleLinkClick}>
                  Not Interested
                </Link>
              </li>
              <li className={`${activeTab === '/loan/configuration' && 'active'}`}>
                <Link to="/loan/configuration" onClick={handleLinkClick}>
                  Configuration
                </Link>
              </li>
            </ul>
          </li>
        )}

        {((user && user.role_id === 1) || (categoryId && categoryId.includes(4))) && (
          <li>
            <div
              className={`submenu-toggle ${openSubmenus['mediclaim'] ? 'open' : ''}`}
              onClick={() => toggleSubmenu('mediclaim')}
            >
              <i className="bi bi-heart-pulse"></i>
              <span>Mediclaim</span>
              <i className={`submenu-arrow bi ${openSubmenus['mediclaim'] ? 'bi-chevron-up' : 'bi-chevron-down'}`}></i>
            </div>
            <ul className={`submenu ${openSubmenus['mediclaim'] ? 'open' : ''}`}>
              <li className={`${activeTab === '/mediclaim' && 'active'}`}>
                <Link to="/mediclaim" onClick={handleLinkClick}>
                  Mediclaim Consumer
                </Link>
              </li>
              <li className={`${activeTab === '/mediclaim/company' && 'active'}`}>
                <Link to="/mediclaim/company" onClick={handleLinkClick}>
                  Mediclaim Company
                </Link>
              </li>
            </ul>
          </li>
        )}

        {user && user.role_id === 1 && (
          <li className={`${activeTab === '/user' && 'active'}`}>
            <Link to="/user" onClick={handleLinkClick}>
              <i className="bi bi-person-lines-fill"></i>
              <span>Role Manager</span>
            </Link>
          </li>
        )}
      </ul>

      {/*<div className="sidebar-footer">
        <button className="logout-btn" onClick={handleLogout}>
          <i className="bi bi-box-arrow-right"></i>
          Logout
        </button>
        <button className="toggle-btn" onClick={toggleSidebar}>
          <i className="bi bi-arrow-bar-left"></i>
        </button>
      </div>*/}
    </div>
  );
};

export default Sidebar;
