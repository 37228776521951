import React, { useState, useEffect } from 'react';
import './popup-u.css';
import { addMediclaimProduct, updateMediclaimProduct } from '../serviceAPI/userAPI';
import { useToaster } from './Toaster';
import { useParams } from 'react-router';

const Popup = ({ isOpen, onClose, fetchApi, initialData }) => {
  const addToast = useToaster();
  const { id } = useParams();
  const [formValues, setFormValues] = useState({
    name: '',
  });
  const [files, setFiles] = useState([]); // To manage uploaded files

  useEffect(() => {
    if (initialData && initialData.mediclaim_company_id) {
      setFormValues({
        name: initialData?.mediclaim_product_name || '',
      });
    } else {
      setFormValues({
        name: '',
      });
    }
  }, [initialData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    if (selectedFiles.length + files.length > 3) {
      addToast('You can upload a maximum of 3 PDF files.', 'error');
      return;
    }
    const validFiles = selectedFiles.filter((file) => file.type === 'application/pdf');
    if (validFiles.length !== selectedFiles.length) {
      addToast('Only PDF files are allowed.', 'error');
      return;
    }
    setFiles((prevFiles) => [...prevFiles, ...validFiles]);
  };

  const handleFileRemove = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('mediclaim_product_name', formValues.name.trim());
    formData.append('mediclaim_company_id', id);

    files.forEach((file, index) => {
      formData.append(`pdfFile${index + 1}`, file);
    });

    let response;
    if (initialData && initialData.mediclaim_product_id) {
      formData.append('mediclaim_product_id', initialData.mediclaim_product_id);
      response = await updateMediclaimProduct(formData, addToast);
    } else {
      response = await addMediclaimProduct(formData, addToast);
    }

    if (response.status) {
      fetchApi();
      onClose();
    } else {
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content" onClick={(e) => e.stopPropagation()}>
        <div className="popup-header d-flex justify-content-between">
          <h2>{initialData ? 'Edit Mediclaim Product' : 'Add Mediclaim Product'}</h2>
          <span className="close-btn" onClick={onClose}>&times;</span>
        </div>
        <form className="popup-form" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6 mb-4">
              <input
                type="text"
                name="name"
                className="form-control"
                placeholder="Product Name"
                value={formValues.name}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="col-md-6 mb-4">
              <input
                type="file"
                className="form-control"
                accept="application/pdf"
                multiple
                onChange={handleFileChange}
              />
              {files.length > 0 && (
                <div className="uploaded-files">
                  {files.map((file, index) => (
                    <div key={index} className="file-item">
                      {file.name}
                      <button type="button" onClick={() => handleFileRemove(index)}>
                        Remove
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <button className="btn btn-blue" type="submit">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Popup;
