import React from 'react';
import './Blog.css';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer.js';
import BlogHome from './BlogHome.js';
import Breadcrumb from '../components/Breadcrumb.js';

const Blog = () => {
    return (
        <>
        <section className='breadcrumbs'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                    <h3>Blog</h3>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Blog</li>
                        </ol>
                    </nav>
                    </div>
                </div>
            </div>
        </section>
        <div className="blog">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                    </div>
                    <BlogHome />
                </div>
            </div>
        </div>
        < Footer />
        </>
    );
};

export default Blog;