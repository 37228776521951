import React, { useState, useEffect } from 'react';
import './popup-u.css';
import { addUnitByBuilder, getAllBuilders, updateUnitByBuilder } from '../serviceAPI/userAPI';
import { useToaster } from './Toaster';

const Popup = ({ isOpen, onClose, fetchApi, initialData }) => {
    const addToast = useToaster();
    const [userType, setUserType] = useState('');
    const [builderData, setBuilderData] = useState([]);
    const [formValues, setFormValues] = useState({
        unit_name: '',
        address: '',
    });
    const [checkboxes, setCheckboxes] = useState({
        Showroom: false,
        Flat: false,
        Office: false,
        House: false
    });
    const [categoriesData, setCategoriesData] = useState({
        Showroom: [],
        Flat: [],
        Office: [],
        House: []
    });
    const [totalEntities, setTotalEntities] = useState(0);

    useEffect(() => {
        getBuilderData();
    }, []);

    useEffect(() => {
        if (initialData && initialData.unit_id) {
            setFormValues({
                unit_name: initialData.unit_name,
                address: initialData.address
            });
            setUserType(initialData.builder_id);
            setCheckboxes({
                Showroom: initialData.unit_categories?.includes('1') || false,
                Flat: initialData.unit_categories?.includes('3') || false,
                Office: initialData.unit_categories?.includes('2') || false,
                House: initialData.unit_categories?.includes('4') || false
            });
            setCategoriesData({
                Showroom: initialData?.Showroom?.wings || [],
                Flat: initialData?.Flat?.wings || [],
                Office: initialData?.Office?.wings || [],
                House: initialData?.House?.wings || []
            })
        } else {
            setFormValues({
                unit_name: '',
                address: ''
            });
            setCheckboxes({
                Showroom: false,
                Flat: false,
                Office: false,
                House: false
            });
            setCategoriesData({
                Showroom: [],
                Flat: [],
                Office: [],
                House: []
            })
        }
    }, [initialData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setCheckboxes((prevState) => ({
            ...prevState,
            [name]: checked
        }));

        if (checked) {
            setCategoriesData((prev) => ({
                ...prev,
                [name]: [{
                    wingName: 'Wing 1',
                    floors: [{
                        floorNumber: 1,
                        startRange: 1,
                        endRange: 5
                    }]
                }]
            }));
        } else {
            setCategoriesData((prev) => ({
                ...prev,
                [name]: []
            }));
        }
        updateTotalEntities();
    };

    const getBuilderData = async () => {
        try {
            const builderData = await getAllBuilders();
            if (builderData?.data?.length > 0) {
                setBuilderData(builderData.data);
                setUserType(builderData.data[0]['builderuser.builder_id']);
            }
        } catch (error) {
            console.error('Error fetching builder data:', error);
        }
    };
    // console.log(categoriesData, 'categoriesData')

    const addWing = (category) => {
        setCategoriesData((prev) => {
            const newWing = {
                wingName: `Wing ${prev[category].length + 1}`,
                floors: [{
                    floorNumber: 1,
                    startRange: 1,
                    endRange: 5
                }]
            };
            return {
                ...prev,
                [category]: [...prev[category], newWing]
            };
        });
        updateTotalEntities();
    };

    const removeWing = (category, wingIndex) => {
        setCategoriesData((prev) => {
            const newCategoriesData = { ...prev };
            newCategoriesData[category].splice(wingIndex, 1); // Remove the wing at the specified index
            return newCategoriesData;
        });
        updateTotalEntities();
    };

    const addFloor = (category, wingIndex) => {
        // console.log('sdjksjkd')
        const newCategoriesData = { ...categoriesData };

        // Get the selected wing and its current floors
        const wing = newCategoriesData[category][wingIndex];
        const lastFloor = wing.floors[wing.floors.length - 1];

        // Calculate the new floor details based on the last floor
        const newFloor = {
            floorNumber: lastFloor ? lastFloor.floorNumber + 1 : 1,
            startRange: lastFloor ? lastFloor.endRange + 1 : 1,
            endRange: lastFloor ? lastFloor.endRange + 5 : 5, // Assuming each floor has 5 units
        };

        // console.log('Adding new floor:', newFloor);

        // Add the new floor to the selected wing
        wing.floors.push(newFloor);

        // Update the categoriesData state with the new data after making all changes
        setCategoriesData(newCategoriesData);

        updateTotalEntities();
    };


    const removeFloor = (category, wingIndex) => {
        const newCategoriesData = { ...categoriesData };

        // Get the selected wing and its current floors
        const wing = newCategoriesData[category][wingIndex];
        if (wing.floors.length > 1) {
            wing.floors.pop();
        }

        // Update the categoriesData state with the new data after making all changes
        setCategoriesData(newCategoriesData);
        updateTotalEntities();
    };

    const updateFloorRange = (category, wingIndex, floorIndex, field, value) => {
        const newCategoriesData = { ...categoriesData };
        const wing = newCategoriesData[category][wingIndex];
        const floor = wing.floors[floorIndex];
        const newValue = parseInt(value);
        floor[field] = newValue;

        setCategoriesData(newCategoriesData);
        updateTotalEntities();
    };

    const handleWingNameChange = (category, wingIndex, newWingName) => {
        setCategoriesData((prev) => {
            const newCategoriesData = { ...prev };
            newCategoriesData[category][wingIndex].wingName = newWingName;
            return newCategoriesData;
        });
    };

    const updateTotalEntities = () => {
        let total = 0;
        Object.keys(categoriesData).forEach((category) => {
            categoriesData[category].forEach((wing) => {
                wing.floors.forEach((floor) => {
                    total += floor.endRange - floor.startRange + 1;
                });
            });
        });
        setTotalEntities(total);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();


        console.log(categoriesData, 'sdsds')

        const transformedCategoriesData = transformAndCalculateSummary(categoriesData);
        console.log(transformedCategoriesData, 'updatedCategoriesData')

        const userData = {
            unit_name: formValues.unit_name.trim(),
            address: formValues.address,
            builder_id: userType,
            Showroom: checkboxes.Showroom ? transformedCategoriesData.Showroom : [],
            Flat: checkboxes.Flat ? transformedCategoriesData.Flat : [],
            Office: checkboxes.Office ? transformedCategoriesData.Office : [],
            House: checkboxes.House ? transformedCategoriesData.House : []
        };

        const unitCategories = [
            { name: 'Showroom', id: 1 },
            { name: 'Flat', id: 3 },
            { name: 'Office', id: 2 },
            { name: 'House', id: 4 }
        ];

        console.log(formValues, 'sdsds')
        userData.unit_categories = unitCategories
            .filter((category) => checkboxes[category.name])
            .map((category) => String(category.id));

        console.log(userData, 'userData')

        try {
            let response;
            if (initialData && initialData.unit_id) {
                userData.unit_id = initialData.unit_id;
                response = await updateUnitByBuilder(userData, addToast);
            } else {
                console.log(userData, 'userData')
                response = await addUnitByBuilder(userData, addToast);
            }

            if (response.status) {
                fetchApi();
                onClose();
            } else {
                onClose();
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            onClose();
        }
    };

    const transformAndCalculateSummary = (data) => {
        const transformedData = {};

        Object.keys(data).forEach((category) => {
            const details = data[category];
            let totalCount = 0;
            let wingCount = 0;
            let floorCount = 0;

            details.forEach((wing) => {
                wingCount++;
                wing.floors.forEach((floor) => {
                    floorCount++;
                    totalCount += floor.endRange - floor.startRange + 1; // Include both start and end
                });
            });

            transformedData[category] = {
                wings: details,
                summary: {
                    totalCount,
                    wingCount,
                    floorCount,
                },
            };
        });

        return transformedData;
    };

    // console.log(builderData, 'sdds')

    if (!isOpen) return null;

    return (
        <div className="popup-overlay">
            <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                <div className='popup-header d-flex justify-content-between align-items-center'>
                    <h2>{initialData ? 'Edit Unit Data' : 'Add Unit Data'}</h2>
                    <span className="close-btn" onClick={onClose}>&times;</span>
                </div>
                <form className="popup-form" onSubmit={handleSubmit}>
                    <div className='row'>
                        <div className='col-md-6 mb-4'>
                        <label className="form-label">Select Builder</label>
                            <select value={userType} className='form-select' onChange={(e) => setUserType(e.target.value)} required>
                                <option value="" disabled>Select Builder</option>
                                {builderData && builderData.length && builderData.map(item => (
                                    <option key={item['builderuser.builder_id']} value={item['builderuser.builder_id']}>
                                        {item['builderuser.company_name']}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='col-md-6 mb-4'>
                        <label className="form-label">Building Name</label>
                            <input type="text" name="unit_name" className='form-control' placeholder="Building Name" value={formValues.unit_name} onChange={handleInputChange} required />
                        </div>
                        <div className='col-md-6 mb-4'>
                        <label className="form-label">Address</label>
                            <textarea name="address" className='form-control' placeholder="Address" value={formValues.address} onChange={handleInputChange} required />
                        </div>
                    </div>
                    <label className="form-label">Assign Category</label>
                    {Object.keys(checkboxes).map((category) => (
                        <div key={category} className='mb-4'>
                            <input type="checkbox" id={`${category}Checkbox`} name={category} checked={checkboxes[category]} onChange={handleCheckboxChange} />
                            <label htmlFor={`${category}Checkbox`} className='ms-2'>{category}</label>
                            {checkboxes[category] && (
                                <div className='ms-4'>
                                    {categoriesData && categoriesData[category].map((wing, wingIndex) => (
                                        <div key={wingIndex} className='mb-3'>
                                            <h5>
                                            <label className="form-label">Wing Name</label>
                                                <input
                                                    type="text"
                                                    value={wing.wingName}
                                                    onChange={(e) => handleWingNameChange(category, wingIndex, e.target.value)}
                                                    className="form-control"
                                                />
                                            </h5>
                                            <button type="button" className='btn btn-sm btn-success me-2' onClick={() => addFloor(category, wingIndex)}>Add Floor</button>
                                            <button type="button" className='btn btn-sm btn-danger' onClick={() => removeFloor(category, wingIndex)}>Remove Floor</button>
                                            <button type="button" className='btn btn-sm btn-danger ms-2' onClick={() => removeWing(category, wingIndex)}>Remove Wing</button>
                                            {wing.floors.map((floor, floorIndex) => (
                                                <div key={floorIndex} className='ms-4'>
                                                    <label className="form-label">Floor Number
                                                        <input
                                                            type="number"
                                                            value={floor.floorNumber}
                                                            onChange={(e) =>
                                                                updateFloorRange(category, wingIndex, floorIndex, 'floorNumber', e.target.value)
                                                            }
                                                            className='ms-2'
                                                        />
                                                    </label>
                                                    <label className="form-label">
                                                        Floor Range:
                                                        <input
                                                            type="number"
                                                            value={floor.startRange}
                                                            onChange={(e) => updateFloorRange(category, wingIndex, floorIndex, 'startRange', e.target.value)}
                                                            className='ms-2'
                                                        />
                                                        &nbsp;&nbsp;-
                                                        <label>
                                                            <input
                                                                type="number"
                                                                value={floor.endRange}
                                                                onChange={(e) => updateFloorRange(category, wingIndex, floorIndex, 'endRange', e.target.value)}
                                                                className='ms-2'
                                                            /></label>
                                                    </label>

                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                    <button type="button" className='btn btn-primary mt-2' onClick={() => addWing(category)}>Add Wing</button>
                                </div>
                            )}
                        </div>
                    ))}
                    <button type="submit" className='btn btn-blue'>Submit</button>
                </form>
            </div>
        </div>
    );
};

export default Popup;
