import React, { useEffect, useState } from "react";
import "./Unit.css"; // Ensure the updated styles match the sheet booking UI
import UnitPopup from "../components/Unit-popup";
import DashboardLayout from "../components/DashboardLayout";
import { useNavigate, useParams } from "react-router-dom";
import { getConsumerByUnit } from "../serviceAPI/userAPI";
import { useToaster } from "../components/Toaster";

const Unit = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [data, setData] = useState(null);
  const [wingData, setWingData] = useState(null); // Transformed wing data
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedWing, setExpandedWing] = useState(null); // Track the selected wing
  const [expandedFloors, setExpandedFloors] = useState({}); // Track the selected floor
  const { id } = useParams(); // Unit ID from route
  const navigate = useNavigate();
  const addToast = useToaster();

  useEffect(() => {
    fetchUnitData();
  }, []);

  const fetchUnitData = async () => {
    setLoading(true);
    try {
      if (id) {
        const response = await getConsumerByUnit({ unit_id: id }, addToast);
        if (response?.data?.length) {
          setData(response.data[0]);
          processWingData(response.data[0]); // Transform the data
        } else {
          throw new Error("No data found");
        }
      } else {
        navigate("/builder/building");
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const processWingData = (rawData) => {
    const wings = {};

    ["Showroom", "Office", "Flat", "House"].forEach((categoryName, index) => {
      if (rawData[categoryName]?.length) {
        rawData[categoryName].forEach((wing) => {
          if (!wings[wing.wingName]) {
            wings[wing.wingName] = {
              wingName: wing.wingName,
              categories: [],
            };
          }
          wings[wing.wingName].categories.push({
            name: categoryName,
            id: categoryName == 'Showroom' ? 1 : categoryName == 'Office' ? 2 : categoryName == 'Flat' ? 3 : 4,
            floors: wing.floors.sort((a, b) => a.floorNumber - b.floorNumber),
          });
        });
      }
    });

    setWingData(Object.values(wings)); // Convert to array format
  };

  const renderWings = () => {
    return wingData.map((wing) => (
      <div
        key={wing.wingName}
        className={`wing-box ${expandedWing === wing.wingName ? "active" : ""}`}
        onClick={() => handleWingClick(wing.wingName)}
      >
        <span className="wing-title">{wing.wingName}</span>
      </div>
    ));
  };

  console.log(expandedFloors, 'expandedFloors')
  const renderFloors = () => {
    if (!expandedWing) return null;

    const selectedWing = wingData.find((wing) => wing.wingName === expandedWing);

    return selectedWing.categories.map((category) => (
      <div key={category.name} className="category-section">
        <h3 className="category-title">{category.name}</h3>
        <div className="floor-container">
          {category.floors.map((floor) => (
            <div key={floor.floorNumber}>
              <div
                className={`floor-box ${expandedFloors[expandedWing]?.includes(floor.floorNumber) ? "active" : ""
                  }`}
                onClick={() => handleFloorClick(floor.floorNumber, expandedWing)}
              >
                Floor {floor.floorNumber}
              </div>
              {/* Units rendered below the floor */}
              {expandedFloors[expandedWing]?.includes(floor.floorNumber) && renderUnits(floor, category)}
            </div>
          ))}
        </div>
      </div>
    ));
  };

  const renderUnits = (floor, category) => {
    if (!floor) return null;

    return (
      <div className="unit-grid">
        {Array.from(
          { length: floor.endRange - floor.startRange + 1 },
          (_, idx) => {
            const unitNumber = floor.startRange + idx;
            const consumer = data?.consumerList?.find(
              (c) =>
                c.office_no === String(unitNumber) &&
                c.wing_id === floor.wingId &&
                c.floor_id === floor.floor_id
            );

            return (
              <div
                className={`unit-cell ${consumer ? "occupied" : "vacant"}`}
                key={unitNumber}
                onClick={() =>
                  handleUnitClick(consumer, unitNumber, floor, category)
                }
              >
                Unit {unitNumber}
              </div>
            );
          }
        )}
      </div>
    );
  };
  console.log(wingData, 'sdsds')

  const handleWingClick = (wingName) => {
    setExpandedWing((prevWing) => {
      const newWing = prevWing === wingName ? null : wingName; // Toggle selected wing
      return newWing;
    });
  };

  const handleFloorClick = (floorNumber, wingName) => {
    setExpandedFloors((prev) => {
      const newExpandedFloors = { ...prev };
      const currentFloors = newExpandedFloors[wingName] || [];

      if (currentFloors.includes(floorNumber)) {
        // If the floor is already expanded, remove it
        newExpandedFloors[wingName] = currentFloors.filter((floor) => floor !== floorNumber);
      } else {
        // If the floor is not expanded, add it
        newExpandedFloors[wingName] = [...currentFloors, floorNumber];
      }

      return newExpandedFloors;
    });
  };

  const handleUnitClick = (consumer, unitNumber, floor, category) => {
    setSelectedUnit({ consumer, unitNumber, floor, category });
    setIsPopupOpen(true);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <DashboardLayout>
      <div className='d-flex justify-content-between align-items-center dashboard-title'>
        <h1>Building {data && data && data?.unit_name}</h1>
      </div>
      <div className="unit-container">
        <div className="wing-container">{renderWings()}</div>
        {expandedWing && <div>{renderFloors()}</div>}
      </div>
      {isPopupOpen && (
        <UnitPopup
          isOpen={isPopupOpen}
          onClose={() => setIsPopupOpen(false)}
          fetchApi={fetchUnitData}
          initialData={selectedUnit?.consumer || { unitNumber: selectedUnit }}
          mainData={selectedUnit}
          fullData={data}
        />
      )}
    </DashboardLayout>
  );
};

export default Unit;
