import React, { useState, useEffect } from "react";
import "./Table.css";
import Search from "./Search";
import { useNavigate, useParams } from "react-router-dom";
import { DOWNLOAD_URL } from "../serviceAPI/userAPI";

const Table = ({
  headings,
  data,
  handleEdit,
  currentPage,
  setCurrentPage,
  itemsPerPage,
  sortColumn,
  sortDirection,
  handleSort,
  setItemsPerPage,
  verticleOptions,
  onVerticalChange,
  onStatusChange,
}) => {
  const [filteredData, setFilteredData] = useState(data);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState({});
  const [inputValue, setInputValue] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    filterData(searchTerm);
  }, [data, searchTerm]);

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    setCurrentPage(1);
  };

  useEffect(() => {
    const statusObject = {};
    const inputObject = {};

    data.forEach((item) => {
      statusObject[item.user_consumer_id] = { status: item?.status };
      inputObject[item.user_consumer_id] = {
        radio: item?.documentSelectedType,
        remarks: item?.remarks,
        amount: item?.amount,
        tenue: item?.tenue,
        rate: item?.rate,
      };
    });

    setSelectedStatus(statusObject);
    setInputValue(inputObject);
  }, []);

  const filterData = (searchTerm) => {
    const newData = data.filter((item) =>
      Object.values(item).some((val) =>
        String(val).toLowerCase().includes(searchTerm)
      )
    );
    setFilteredData(newData);
  };

  const clearSearch = () => {
    setSearchTerm("");
    setFilteredData(data);
  };

  const handleStatusChange = (item, value, key) => {
    const { user_consumer_id } = item;

    if (value === 'login' || value === 'pickup' || value === 'disburse') {
      onStatusChange(item, value, key);
    }

    setSelectedStatus((prev) => ({
      ...prev,
      [user_consumer_id]: { ...prev[user_consumer_id], status: value },
    }));

    setInputValue((prev) => ({
      ...prev,
      [user_consumer_id]: {},
    }));

    if (value === "documentselected") {
      setInputValue((prev) => ({
        ...prev,
        [user_consumer_id]: { radio: "", remarks: "", amount: "", tenue: "", rate: "" },
      }));
    } else if (value === "login") {
      setInputValue((prev) => ({
        ...prev,
        [user_consumer_id]: { remarks: "" },
      }));
    } else if (value === "query") {
      setInputValue((prev) => ({
        ...prev,
        [user_consumer_id]: { remarks: "" },
      }));
    }
  };

  const handleInputChange = (e, userConsumerId, field) => {
    const { value, type } = e.target;

    setInputValue((prev) => ({
      ...prev,
      [userConsumerId]: {
        ...prev[userConsumerId],
        [field]: type === "number" ? parseFloat(value) : value,
      },
    }));
  };


  const sortedData = [...filteredData].sort((a, b) => {
    if (sortColumn) {
      const aValue = String(a[sortColumn]).toLowerCase();
      const bValue = String(b[sortColumn]).toLowerCase();
      if (aValue < bValue) return sortDirection === "asc" ? -1 : 1;
      if (aValue > bValue) return sortDirection === "asc" ? 1 : -1;
    }
    return 0;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = sortedData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handleClick = (event) => {
    setCurrentPage(Number(event.target.id));
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getVisiblePageNumbers = () => {
    const pages = [];
    let startPage, endPage;

    if (totalPages <= 3) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 2) {
        startPage = 1;
        endPage = 3;
      } else if (currentPage >= totalPages - 1) {
        startPage = totalPages - 2;
        endPage = totalPages;
      } else {
        startPage = currentPage - 1;
        endPage = currentPage + 1;
      }
    }

    if (startPage > 1) {
      pages.push(
        <button
          key={1}
          id={1}
          onClick={handleClick}
          className={`page-number ${currentPage === 1 ? "active" : ""}`}
        >
          1
        </button>
      );
      if (startPage > 2) {
        pages.push(
          <span key="start-ellipsis" className="ellipsis">
            ...
          </span>
        );
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          id={i}
          onClick={handleClick}
          className={`page-number ${currentPage === i ? "active" : ""}`}
        >
          {i}
        </button>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pages.push(
          <span key="end-ellipsis" className="ellipsis">
            ...
          </span>
        );
      }
      pages.push(
        <button
          key={totalPages}
          id={totalPages}
          onClick={handleClick}
          className={`page-number ${
            currentPage === totalPages ? "active" : ""
            }`}
        >
          {totalPages}
        </button>
      );
    }

    return pages;
  };

  const handleSubmit = (item, userConsumerId) => {
    console.log(selectedStatus[userConsumerId], inputValue[userConsumerId]);

    if (
      selectedStatus[userConsumerId].status === "documentselected" &&
      inputValue[userConsumerId]?.radio
    ) {
      item.document_name = inputValue[userConsumerId]?.radio;
      onStatusChange(item, "documentselected", "status");
    } else if (
      selectedStatus[userConsumerId].status === "query" &&
      inputValue[userConsumerId]?.remarks
    ) {
      item.query = inputValue[userConsumerId]?.remarks;
      onStatusChange(item, "query", "status");
    } else if (
      selectedStatus[userConsumerId].status === "sanction" &&
      inputValue[userConsumerId]?.amount
    ) {
      console.log('called')
      item.amount = inputValue[userConsumerId]?.amount || 0;
      item.rate = inputValue[userConsumerId]?.rate || 0;
      item.tenue = inputValue[userConsumerId]?.tenue || 0;
      onStatusChange(item, "sanction", "status");
    }
  };

  console.log(inputValue);

  const redirectpage = (pdf) => {
    // var file_path = DOWNLOAD_URL + "public/uploads/" + pdf;
    const file_path = DOWNLOAD_URL + pdf;
    console.log(file_path, "file");
    var a = document.createElement("A");
    a.href = file_path;
    a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
    a.target = "_blank"; // Open in a new tab
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const redirectpageproduct = (pdfPath) => {
    // const file_path = `${DOWNLOAD_URL}public${pdfPath}`;
    const file_path = DOWNLOAD_URL + pdfPath;
    console.log(file_path, "file");

    // Create a temporary <a> element
    const a = document.createElement("a");
    a.href = file_path;
    a.target = "_blank"; // Open in a new tab
    a.download = file_path; // Extract filename from path

    // Trigger the click programmatically
    document.body.appendChild(a);
    a.click();

    // Remove the element after triggering
    document.body.removeChild(a);
  };

  return (
    <div>
      <div className="table-container">
        <div className="table-h">
          <div className="table-h-c">
            <Search
              searchTerm={searchTerm}
              handleSearch={handleSearch}
              clearSearch={clearSearch}
            />
          </div>
          <div className="table-h-c"></div>
          <div className="show-by table-h-c">
            <label>Show by Rows:</label>
            <select
              value={itemsPerPage}
              onChange={(e) => {
                setItemsPerPage(parseInt(e.target.value));
                setCurrentPage(1);
              }}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
        </div>
        <table className="universal-table">
          <thead>
            <tr>
              <th>S.No</th>
              {headings.map((heading, index) => (
                <th key={index} onClick={() => handleSort(heading.key)}>
                  {heading.head}{" "}
                  {sortColumn === heading.key
                    ? sortDirection === "asc"
                      ? "▲"
                      : "▼"
                    : null}
                </th>
              ))}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentData && currentData.length ? (
              currentData.map((item, index) => {
                const { user_consumer_id ,mediclaimproductpdfs} = item;
                let interestedLoanStatus = selectedStatus[user_consumer_id]?.status;
                if(selectedStatus[user_consumer_id]?.status  === "interested"){
                  interestedLoanStatus = ''
                }
                return (
                  <tr key={index}>
                    <td>{indexOfFirstItem + index + 1}</td>
                    {headings &&
                      headings.map((heading, hIndex) => (
                        <td className="show-by" key={hIndex}>
                          {heading.key === "status" ? (
                            <>
                              <select
                                value={interestedLoanStatus || ""}
                                onChange={(e) =>
                                  handleStatusChange(
                                    item,
                                    e.target.value,
                                    heading.key,
                                    user_consumer_id
                                  )
                                }
                              >
                                <option value="">Select Status</option>
                                <option value="documentselected">
                                  Document Select
                                </option>
                                <option value="pickup">Pickup</option>
                                <option value="login">Login</option>
                                <option value="query">Query</option>
                                <option value="sanction">Sanction</option>
                                <option value="disburse">Disburse</option>
                              </select>
                              {interestedLoanStatus ===
                                "documentselected" && (
                                  <>
                                    <div className="input-field">
                                      <input
                                        type="radio"
                                        name={`radio-${user_consumer_id}`}
                                        value="home"
                                        checked={
                                          inputValue[user_consumer_id]?.radio === "home"
                                        }
                                        onChange={(e) =>
                                          handleInputChange(e, user_consumer_id, "radio")
                                        }
                                      />
                                      <label>Home</label>
                                      <br />
                                      <input
                                        type="radio"
                                        name={`radio-${user_consumer_id}`}
                                        value="car"
                                        checked={
                                          inputValue[user_consumer_id]?.radio === "car"
                                        }
                                        onChange={(e) =>
                                          handleInputChange(e, user_consumer_id, "radio")
                                        }
                                      />
                                      <label>Car</label>
                                      <br />
                                      <button
                                        onClick={() => handleSubmit(item, user_consumer_id)}
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </>
                                )}
                              {interestedLoanStatus === "query" && (
                                <>
                                  <div className="input-field">
                                    <input
                                      type="text"
                                      placeholder="Remarks"
                                      value={inputValue[user_consumer_id]?.remarks || ""}
                                      onChange={(e) =>
                                        handleInputChange(e, user_consumer_id, "remarks")
                                      }
                                    />
                                    <br />
                                    <button
                                      onClick={() => handleSubmit(item, user_consumer_id)}
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </>
                              )}
                              {interestedLoanStatus === "sanction" && (
                                <>
                                  <div className="input-field">
                                    <input
                                      type="number"
                                      placeholder="Amount"
                                      value={inputValue[user_consumer_id]?.amount || ""}
                                      onChange={(e) =>
                                        handleInputChange(e, user_consumer_id, "amount")
                                      }
                                    />
                                    <br />
                                    <input
                                      type="number"
                                      placeholder="Rate"
                                      value={inputValue[user_consumer_id]?.rate || ""}
                                      onChange={(e) =>
                                        handleInputChange(e, user_consumer_id, "rate")
                                      }
                                    />
                                    <br />
                                    <input
                                      type="number"
                                      placeholder="Tenue"
                                      value={inputValue[user_consumer_id]?.tenue || ""}
                                      onChange={(e) =>
                                        handleInputChange(e, user_consumer_id, "tenue")
                                      }
                                    />
                                    <br />
                                    <button
                                      onClick={() => handleSubmit(item, user_consumer_id)}
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </>
                              )}
                            </>
                          ) : heading.key === "verticalLoan" ? (
                            <select
                              value={item[heading.key] || ""}
                              onChange={(e) =>
                                onVerticalChange(
                                  item,
                                  e.target.value,
                                  heading.key
                                )
                              }
                            >
                              <option value="">Select Interest</option>
                              <option value="interested">Interested</option>
                              <option value="notInterested">
                                Not Interested
                              </option>
                            </select>
                          ) : heading.key === "roleDisplay" ? (
                            item.category &&
                            item.category.map((Item) => (
                              <div key={Item.id}>
                                (Vertical ={" "}
                                {Item.category_id == 2
                                  ? "LOAN"
                                  : Item.category_id == 4
                                    ? "MEDICLIAM"
                                    : Item.category_id == 5
                                      ? "LIFE INSURANCE"
                                      : "VEHICLE INSURANCE"}{" "}
                                : Role User : {Item["userRoles.username"]})
                              </div>
                            ))
                          ) : heading.key === "pdfname" ? (
                            <>
                              {item.pdfname &&
                                item.pdfname.split(",").map((pdf, idx) => (
                                  <div key={idx}>
                                    <a>
                                      Year: {item.categoryname.split(",")[idx]}{" "}
                                      --{" "}
                                    </a>
                                    <a
                                      href="#"
                                      onClick={() => redirectpage(pdf)}
                                    >
                                      {pdf.slice(37)}
                                    </a>
                                    {idx < item.pdfname.split(",").length - 1
                                      ? ", "
                                      : ""}
                                  </div>
                                ))}
                            </>
                          ) : // item.category && item.category.map((Item) => (
                            //   <div key={Item.id}>
                            //     (Vertical = {Item.category_id == 2 ? 'LOAN' : Item.category_id == 4 ? 'MEDICLIAM' : Item.category_id == 5 ? 'LIFE INSURANCE' : 'VEHICLE INSURANCE'} : Role User : {Item['userRoles.username']})
                            //   </div>
                            // ))
                            heading.key === "mediclaimproductpdfs" ? (
                              <>
                                <div className="pdf-grid">
                                  {mediclaimproductpdfs &&
                                    mediclaimproductpdfs.map((pdf, pdfIndex) => (
                                      <div
                                        key={pdf.mediclaim_pdf_id}
                                        className="pdf-item"
                                        style={{
                                          border: "1px solid #ddd",
                                          padding: "10px",
                                          margin: "5px",
                                          display: "inline-block",
                                        }}
                                      >
                                        <p
                                          style={{
                                            fontSize: "14px",
                                            marginBottom: "5px",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {pdf.pdf_name}
                                        </p>
                                        <a
                                          href={pdf.pdf_path}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          onClick={(e) => {
                                            e.preventDefault(); // Prevent default link behavior
                                            redirectpageproduct(pdf.pdf_path); // Use the redirectpage function
                                          }}
                                          style={{
                                            color: "blue",
                                            textDecoration: "underline",
                                            fontSize: "12px",
                                          }}
                                        >
                                          View PDF
                                        </a>
                                      </div>
                                    ))}
                                </div>
                              </>
                            ) :
                              heading.key === "is_from_builder_user" &&
                                (item.is_from_builder_user == 1 ||
                                  item.is_from_builder_user == 0) ? (
                                <div>
                                  {item.is_from_builder_user ? "Yes" : "No"}
                                </div>
                              ) : (
                                item[heading.key]
                              )}
                        </td>
                      ))}

                    <td>
                      <div
                        style={{ padding: "0px" }}
                        onClick={() => handleEdit(indexOfFirstItem + index)}
                      >
                        <img
                          className="table-edit"
                          src="/Assets/edit.png"
                          alt="edit"
                        />
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={headings.length + 2}>No record found</td>
              </tr>
            )}
          </tbody>
        </table>
        {filteredData && (
          <div className="pagination">
            <button onClick={handlePreviousPage} disabled={currentPage === 1}>
              Previous
            </button>
            {getVisiblePageNumbers()}
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Table;
