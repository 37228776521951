import React, { useState, useEffect } from 'react';
import './popup.css'; // Custom CSS for styling
import { updateLoanStatus } from '../serviceAPI/userAPI'; // API call to update loan status
import { useToaster } from './Toaster'; // Utility to show notifications

const StatusModal = ({ isOpen, onClose, initialData, fetchApi,notInterested }) => {
    const addToast = useToaster();
    const [status, setStatus] = useState(initialData?.status || 'interested');

    useEffect(() => {
        if (initialData) {
            setStatus(initialData.status || 'interested');
        }
    }, [initialData]);
    console.log(initialData, 'loanData')

    const handleStatusChange = async (newStatus) => {

        let loanStatus = {
            status: newStatus,
            user_consumer_id: initialData.user_consumer_id
        }

        const response = await updateLoanStatus(loanStatus, addToast);

        if (response.status) {
            fetchApi();
            onClose();
        } else {
            fetchApi();
        }
    };

    if (!isOpen) return null; // Return null if modal is not open

    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <div className="popup-header">
                    <h2>Update Loan Status</h2>
                    <span className="close-btn" onClick={onClose}>&times;</span>
                </div>
                <form className="popup-form">
                    <div className="form-group">
                        <label className="form-label">Status</label>
                        <div className="status-options">
                            <div className="option">
                                <input
                                    type="radio"
                                    id="interested"
                                    name="status"
                                    value="interested"
                                    checked={status === 'interested'}
                                    onChange={() => setStatus('interested')}
                                />
                                <label htmlFor="interested" style={{ fontWeight: '600', marginLeft: '5px' }}>Interested</label>
                            </div>
                            {!notInterested && <div className="option">
                                <input
                                    type="radio"
                                    id="notInterested"
                                    name="status"
                                    value="notInterested"
                                    checked={status === 'notInterested'}
                                    onChange={() => setStatus('notInterested')}
                                />
                                <label htmlFor="notInterested" style={{ fontWeight: '600', marginLeft: '5px' }}>Not Interested</label>
                            </div>}
                        </div>
                    </div>
                    <div className='text-center'>
                        <button
                            className="btn btn-primary mt-3"
                            type="button"
                            onClick={() => handleStatusChange(status)}
                        >
                            Update Status
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default StatusModal;
