import React from 'react';
import './bloglist.css';
import Footer from '../../components/Footer';

const bloglist3 = () => {
    return (
        <>
        <section className='breadcrumbs'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                    <h3>Blog</h3>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Blog</li>
                        </ol>
                    </nav>
                    </div>
                </div>
            </div>
        </section>
        <div className='bloglist'>            
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <div className="bloglist-img">
                            <img src='/Assets/738.jpg'/>
                        </div>
                        <div className='bloglist-content'>
                            <h1 className='heading'>Blog three title will be shown here.</h1>
                            <p>It seemed like it should have been so simple. There was nothing inherently difficult with getting the project done. It was simple and straightforward enough that even a child should have been able to complete it on time, but that wasn't the case. The deadline had arrived and the project remained unfinished.It seemed like it should have been so simple. There was nothing inherently difficult with getting the project done. It was simple and straightforward enough that even a child should have been able to complete it on time, but that wasn't the case. The deadline had arrived and the project remained unfinished.Green vines attached to the trunk of the tree had wound themselves toward the top of the canopy. Ants used the vine as their private highway, avoiding all the creases and crags of the bark, to freely move at top speed from top to bottom or bottom to top depending on their current chore. At least this was the way it was supposed to be. Something had damaged the vine overnight halfway up the tree leaving a gap in the once pristine ant highway.There weren't supposed to be dragons flying in the sky. First and foremost, dragons didn't exist. They were mythical creatures from fantasy books like unicorns. This was something that Pete knew in his heart to be true so he was having a difficult time acknowledging that there were actually fire-breathing dragons flying in the sky above him.Dave found joy in the daily routine of life. He awoke at the same time, ate the same breakfast and drove the same commute. He worked at a job that never seemed to change and he got home at 6 pm sharp every night. It was who he had been for the last ten years and he had no idea that was all about to change.</p>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
        <Footer/>
        </>
    );
};

export default bloglist3;