import React from 'react';
import './About.css';
import Breadcrumb from '../components/Breadcrumb.js';
import BlogHome from "./BlogHome.js";
import Clientslideraboutpage from '../components/Clientslideraboutpage.js';
import Footer from '../components/Footer.js';

const Services = () => {
    return (
        <>
        <section className='breadcrumbs'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                    <h3>About Us</h3>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">About Us</li>
                        </ol>
                    </nav>
                    </div>
                </div>
            </div>
        </section>
        <section className="contact-info">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="contact-info-content">
                            
                            <div className="img">
                                <img src="./Assets/happy-family.jpg" className="img-fluid"/>
                                <div className="experience">
                                    <h4>25  Years of</h4>
                                    <p>Experience</p>
                                </div> 
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="contact-info-right-content">
                            <h1 className="heading">About Us: Your Trusted Insurance Partner</h1>
                            <p>At Nanak Finserv, we are dedicated to protecting what matters most to you. Since our inception in [Year], we have been committed to providing innovative, reliable, and customer-focused insurance solutions that give you peace of mind and security for the future.</p>
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            What types of insurance do you offer?
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        We offer a range of insurance products, including life insurance, health insurance, vehicle insurance, property insurance, loan insurance, and more.
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
      Do you offer cashless services for health insurance?
      </button>
    </h2>
    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      Yes, our health insurance plans include cashless hospitalization services at a wide network of hospitals nationwide.
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingThree">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
      What sets you apart from other insurers?
      </button>
    </h2>
    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      Our focus on personalized service, innovative solutions, and customer satisfaction makes us stand out. We prioritize building trust and delivering value to our clients.
      </div>
    </div>
  </div>
</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="about">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="about-content">
                            <a href="/about" className="btn mb-4">About us</a>
                            <h1 className="heading">We provide the best solutions to guarantee the future for you and your family!</h1>
                            <p>At Nanak Finserv, your future and your family’s well-being are at the heart of everything we do. With a deep commitment to excellence and trust, we offer comprehensive insurance solutions that secure your life, health, and assets against life’s uncertainties.</p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="about-right-content">
                            <h3 className='heading'>Why Choose Our Solutions?</h3>
                            <ul>
                                <li>Comprehensive Coverage: From safeguarding your health to protecting your financial goals, we’ve got you covered every step of the way.</li>
                                <li>Affordable Options: Flexible premiums to fit your budget without compromising on quality or coverage.</li>
                                <li>Peace of Mind: With us, you can focus on what matters most—living your life—while we handle your future’s security.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='count'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-3 col-md-6'>
                        <div className='count-box text-center'>
                            <i className="fa-solid fa-coins"></i>
                            <h1 className='heading'>1.2 Million</h1>
                            <span>Loan Insurance customers</span>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6'>
                        <div className='count-box text-center'>
                            <i className="fa-solid fa-car-burst"></i>
                            <h1 className='heading'>1.2 Million</h1>
                            <span>Vehicle Insurance customers</span>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6'>
                        <div className='count-box text-center'>
                            <i className="fa-solid fa-heart-pulse"></i>
                            <h1 className='heading'>1.2 Million</h1>
                            <span>Life Insurance customers</span>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6'>
                        <div className='count-box text-center'>
                            <i className="fa-solid fa-tablets"></i>
                            <h1 className='heading'>1.2 Million</h1>
                            <span>Mediclaim Insurance customers</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="blog">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="blog-heading text-center">
                        <a href="/blog" className="btn mb-4">Blog</a>
                        <h1 className="heading">Recent Blogs</h1>
                        </div>
                    </div>
                    <BlogHome />
                </div>
            </div>
        </section>
        <section className="clients">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1 className="heading text-center mb-4">Our Clients</h1>
                        <Clientslideraboutpage />
                    </div>
                </div>
            </div>
        </section>       
        <Footer />
        </>
    );
};

export default Services;
