import React from 'react';
import './BlogHome.css';
import { Link } from 'react-router-dom';

const BlogHome = () => {
    return (
        <>
            <div className='row blog-home'>
                <div className='col-lg-4 col-md-6 mb-4'>
                    <div className='blog-box'>
                        <div className='blog-box-img'>
                            <img src='/Assets/are-you-covered.jpg' alt="Blog 1" className='img-fluid' />
                        </div>
                        <div className='blog-box-content'>
                            <h3>Blog one title will be shown here.</h3>
                            <p>It seemed like it should have been so simple. There was nothing inherently difficult with getting the project done. It was simple and...</p>
                            <Link to='/bloglist1' className='read-more'>Read More <i className="bi bi-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>

                <div className='col-lg-4 col-md-6 mb-4'>
                    <div className='blog-box'>
                        <div className='blog-box-img'>
                            <img src='/Assets/738.jpg' alt="Blog 2" className='img-fluid' />
                        </div>
                        <div className='blog-box-content'>
                            <h3>Blog two title will be shown here.</h3>
                            <p>It seemed like it should have been so simple. There was nothing inherently difficult with getting the project done. It was simple and...</p>
                            <Link to='/bloglist2' className='read-more'>Read More <i className="bi bi-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>

                <div className='col-lg-4 col-md-6 mb-4'>
                    <div className='blog-box'>
                        <div className='blog-box-img'>
                            <img src='/Assets/738.jpg' alt="Blog 3" className='img-fluid' />
                        </div>
                        <div className='blog-box-content'>
                            <h3>Blog three title will be shown here.</h3>
                            <p>It seemed like it should have been so simple. There was nothing inherently difficult with getting the project done. It was simple and...</p>
                            <Link to='/bloglist3' className='read-more'>Read More <i className="bi bi-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BlogHome;
