import React, { useState, useEffect } from 'react';
import './popup-u.css';
import { addConsumerUser, addNewCode, getAllBuildersList, getAllCodes, updateConsumerUser, updateLoanConsumerUser } from '../serviceAPI/userAPI';
import Cookies from 'js-cookie';
import { useToaster } from './Toaster';
import FlagDropdown from '../pages/Flag';

const LoanConsumerPopup = ({ isOpen, onClose, fetchApi, initialData }) => {
    const [formState, setFormState] = useState({
        formData: {
            Name: '',
            MobileNumber: '',
            Email: ''
        }
    });

    const [status, setStatus] = useState('');
    const [inputValue, setInputValue] = useState({});
    const user = (Cookies.get('user') && JSON.parse(Cookies.get('user'))) || '';
    const [showAddCodeField, setShowAddCodeField] = useState(false);
    const [isProperty, setIsProperty] = useState(false);
    const [codes, setCodes] = useState([]); // To store the list of codes
    const [newCodeName, setNewCodeName] = useState(''); // New code input field
    console.log(user, 'user')

    useEffect(() => {
        const fetchData = async () => {
            if (initialData && initialData.user_consumer_id) {
                setFormState(prevState => ({
                    ...prevState,
                    formData: {
                        Name: initialData?.['userConsumers.username'] || '',
                        MobileNumber: initialData?.['userConsumers.mobileNumber'] || '',
                        Email: initialData?.['userConsumers.email'] || ''
                    },
                }));
                if (initialData?.details?.status && ['documentselected', 'query', 'sanction', 'login', 'disbursement', 'pickup', 'partPayment', 'cancel', 'interested'].includes(initialData?.details?.status)) {
                    if (initialData?.details?.status && initialData?.details?.status != "interested") {
                        setStatus(initialData?.details?.status);
                    }
                    const details = initialData.details || {};
                    setInputValue(prevState => ({
                        ...prevState,
                        ...(details.login_details || {}),
                        ...(details.sanction_details || {}),
                        ...(details.query_details || {}),
                        ...({ ['radio-loanType']: details?.document_details?.loan_type,['loan-Type']:details?.document_details?.loan_type_name } || {}),
                        ...(details.disbursement_details || {}),
                        parts: details.part_details?.parts || [{ part_amount: "", part_date: "" }],
                    }));
                    if (!(initialData?.details?.builder_consumer_details && initialData?.details?.builder_consumer_details?.builderuser && initialData?.details?.builder_consumer_details?.floor)) {
                        setIsProperty(true)
                        setInputValue(prevState => ({
                            ...prevState,
                            ...(details.property_details || {}),
                        }));
                    }
                } else {
                    setStatus('')
                }

            } else {
                setFormState(prevState => ({
                    ...prevState,
                    formData: {
                        Name: '',
                        MobileNumber: '',
                        Email: ''
                    }
                }));
            }
            fetchCodes();
        }
        fetchData();

    }, [initialData]);


    const fetchCodes = async () => {
        const consumerData = await getAllCodes();
        if (consumerData?.data && consumerData?.data?.length) {
            setCodes(consumerData?.data);
        } else {
            setCodes([]);
        }
    };

    const handleInputChange = (e, field) => {
        const { name, value } = e.target;
        setInputValue(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    console.log(inputValue)
    const handleSubmit = async (e) => {
        e.preventDefault();

        const regex = /^\d{10}$/;
        if (!regex.test(formState.formData.MobileNumber)) {
            alert('Mobile number is invalid');
            return;
        }
        const userData = {
            username: formState.formData.Name.trim(),
            phone_number: formState.formData.MobileNumber,
            email: formState.formData.Email,
        };

        const selectedCategories = [];
        selectedCategories.push({ category_id: 2, user_role_id: user.user_id });
        if (!initialData || !initialData.user_consumer_id) {
            userData.category = selectedCategories
        }

        console.log(inputValue,'inputValue')
        if (initialData && initialData.user_consumer_id) {
            if (status === 'documentselected') {
                userData.document_details = {
                    loan_type: inputValue?.['radio-loanType'],
                    loan_type_name: inputValue?.['loan-Type']
                };
            } else if (status === 'query') {
                userData.query_details = {
                    remarks: inputValue?.['remarks']
                };
            } else if (status === 'sanction') {
                userData.sanction_details = {
                    amount: inputValue?.['amount'],
                    rate: inputValue?.['rate'],
                    tenure: inputValue?.['tenure']
                };
            } else if (status === 'login') {
                userData.login_details = {
                    loanAmount: inputValue?.['loanAmount'],
                    loanDate: inputValue?.['loanDate'],
                    loanAccountNumber: inputValue?.['loanAccountNumber'],
                    bankName: inputValue?.['bankName'],
                    product: inputValue?.['product'],
                    smName: inputValue?.['smName'],
                    dateOfBirth: inputValue?.['dateOfBirth'],
                    code_id: inputValue?.['code']
                };
                if (isProperty) {
                    userData.property_details = {
                        address: inputValue?.['address'],
                        sqFeet: inputValue?.['sqFeet'],
                        deedAmount: inputValue?.['deedAmount'],
                    };
                }
            } else if (status === 'disbursement') {
                userData.disbursement_details = {
                    disbursementAmount: inputValue?.['disbursementAmount'],
                    disbursementRate: inputValue?.['disbursementRate'],
                    insurance: inputValue?.['insurance'],
                    fileNumber: inputValue?.['fileNumber'],
                    disbursementDate: inputValue?.['disbursementDate']
                };
            } else if (status === 'partPayment') {
                userData.part_details = {
                    parts: inputValue?.['parts']?.map(part => {
                        if (part?.part_id) {
                            return { ...part, part_id: part.part_id };
                        }
                        return { ...part };
                    })
                };
            }
            userData.status = status;
            userData.user_consumer_id = initialData.user_consumer_id;
            userData.laon_id = initialData?.details?.laon_id;
        }
        console.log(userData, 'sdjksj')
        if (initialData && initialData.user_consumer_id && userData.laon_id) {
            const response = await updateLoanConsumerUser(userData);
            if (response.status) {
                fetchApi();
                onClose();
            } else {
                onClose();
            }
        } else {
            const response = await addConsumerUser(userData);
            if (response.status) {
                fetchApi();
                onClose();
            } else {
                onClose();
            }
        }
    };

    console.log(initialData, 'initialData')

    const handlePartInputChange = (e, index, field) => {
        const { value } = e.target;
        const updatedParts = [...inputValue.parts];
        updatedParts[index] = {
            ...updatedParts[index],
            [field]: value
        };

        setInputValue(prevState => ({
            ...prevState,
            parts: updatedParts
        }));
    };

    const handleAddPart = () => {
        setInputValue((prevState) => ({
            ...prevState,
            parts: [
                ...prevState.parts,
                { part_amount: "", part_date: "" }, // Default new part
            ],
        }));
    };

    const handleRemovePart = (index) => {
        const updatedParts = inputValue.parts.filter((_, i) => i !== index);

        setInputValue(prevState => ({
            ...prevState,
            parts: updatedParts
        }));
    };

    const getAvailableOptions = () => {
        // List of all possible options
        const allOptions = [
            { value: "documentselected", label: "Document Selected" },
            { value: "pickup", label: "Pickup" },
            { value: "login", label: "Login" },
            { value: "query", label: "Query" },
            { value: "cancel", label: "Cancel" },
            { value: "sanction", label: "Sanction" },
            { value: "partPayment", label: "Part Payment" },
            { value: "disbursement", label: "Disbursement" },
            { value: "completed", label: "Completed" }
        ];

        if (initialData?.details?.status === "cancel" && user.role_id !== 1) {
            return allOptions.filter(option => option.value === "cancel");
        }

        // Remove options based on dynamic conditions
        if (initialData?.details?.status === "pickup" && user.role_id !== 1) {
            return allOptions.filter(option => option.value !== "documentselected");
        } else if (initialData?.details?.status === "login" && user.role_id !== 1) {
            return allOptions.filter(option => option.value !== "documentselected" && option.value !== "pickup");
        } else if (initialData?.details?.status === "query" && user.role_id !== 1) {
            return allOptions.filter(option => option.value !== "documentselected" && option.value !== "pickup" && option.value !== "login");
        } else if (initialData?.details?.status === "cancel" && user.role_id !== 1) {
            return allOptions.filter(option => option.value !== "documentselected" && option.value !== "pickup" && option.value !== "login" && option.value !== "query");
        } else if (initialData?.details?.status === "sanction" && user.role_id !== 1) {
            return allOptions.filter(option => option.value !== "documentselected" && option.value !== "pickup" && option.value !== "login" && option.value !== "query" && option.value !== "cancel");
        } else if (initialData?.details?.status === "partPayment" && user.role_id !== 1) {
            return allOptions.filter(option => option.value !== "documentselected" && option.value !== "pickup" && option.value !== "login" && option.value !== "query" && option.value !== "cancel" && option.value !== "sanction");
        } else if (initialData?.details?.status === "disbursement" && user.role_id !== 1) {
            return allOptions.filter(option => option.value !== "documentselected" && option.value !== "pickup" && option.value !== "login" && option.value !== "query" && option.value !== "cancel" && option.value !== "sanction" && option.value !== "partPayment");
        } else {
            // Allow all other options
            return allOptions;
        }
    };

    const handleAddCodeClick = () => {
        setShowAddCodeField(true); // Show the input field and save button
    };

    const handleSaveNewCode = async () => {
        if (!newCodeName.trim()) {
            alert("Code name cannot be empty");
            return;
        }

        const response = await addNewCode({ code_name: newCodeName });
        if (response.status) {
            console.log(response.data.code_name, 'sdjsj')
            fetchCodes();
            setNewCodeName('');
            setShowAddCodeField(false); // Hide the input field and save button
        } else {
            alert("Failed to add code");
        }
    };

    useEffect(() => {
        getAvailableOptions();
    }, [initialData?.details?.status]);

    if (!isOpen) return null;
    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <div className='popup-header d-flex justify-content-between align-items-center'>
                    <h2>{initialData ? 'Edit Loan Consumer' : 'Add Loan Consumer'}</h2>
                    <span className="close-btn" onClick={onClose}>&times;</span>
                </div>
                <form className="popup-form" onSubmit={handleSubmit}>
                    <div className='row'>
                        <div className='col-md-6 mb-4'>
                            <label className="form-label">Name</label>
                            <input
                                type="text"
                                name="Name"
                                className='form-control'
                                placeholder="Name"
                                value={formState.formData.Name}
                                onChange={(e) => setFormState(prevState => ({
                                    ...prevState,
                                    formData: {
                                        ...prevState.formData,
                                        Name: e.target.value
                                    }
                                }))}
                            />
                        </div>
                        <div className='col-md-6 mb-4'>
                            <label className="form-label">Email</label>
                            <input
                                type="email"
                                name="Email"
                                className='form-control'
                                placeholder="Email"
                                value={formState.formData.Email}
                                onChange={(e) => setFormState(prevState => ({
                                    ...prevState,
                                    formData: {
                                        ...prevState.formData,
                                        Email: e.target.value
                                    }
                                }))}
                            />
                        </div>
                        <div className='col-md-6 mb-4'>
                            <label className="form-label">Mobile Number</label>
                            <div style={{ display: 'flex' }}>
                                <FlagDropdown />
                                <input
                                    type="text"
                                    name="MobileNumber"
                                    className='form-control'
                                    style={{ margin: '0px' }}
                                    placeholder="Mobile Number"
                                    value={formState.formData.MobileNumber}
                                    onChange={(e) => setFormState(prevState => ({
                                        ...prevState,
                                        formData: {
                                            ...prevState.formData,
                                            MobileNumber: e.target.value
                                        }
                                    }))}
                                />
                            </div>

                        </div>
                        {initialData && initialData.user_consumer_id && <>
                            <div className="col-md-6 mb-4">
                                <label className="form-label">Select Status</label>
                                <select
                                    className="form-select"
                                    value={status}
                                    onChange={(e) => setStatus(e.target.value)}
                                    required
                                >
                                    <>
                                        <option value={''} disabled>
                                            {'Select Status'}
                                        </option>
                                        {getAvailableOptions().map(option => (
                                            <>
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            </>

                                        ))}
                                    </>

                                </select>
                            </div>

                            {status === "documentselected" && (
                                <div className="col-md-12 mb-4">
                                    <div className="row">
                                        <div className="col-md-6 mb-4">
                                            <label className="form-label">Select Loan Type</label>
                                            <select
                                                name="radio-loanType"
                                                className="form-select"
                                                value={inputValue['radio-loanType']}
                                                onChange={(e) => handleInputChange(e, "radio-loanType")}
                                            >
                                                <option value="" disabled>Select Loan Type</option>
                                                <option key={1} value={"Home"}>
                                                    {"Home"}
                                                </option>
                                                <option key={2} value={"Car"}>
                                                    {"Car"}
                                                </option>
                                                <option key={3} value={"Personal"}>
                                                    {"Personal"}
                                                </option>
                                                <option key={4} value={"Buissness"}>
                                                    {"Buissness"}
                                                </option>
                                                <option key={5} value={"NRP"}>
                                                    {"NRP"}
                                                </option>
                                                <option key={6} value={"CC && OD"}>
                                                    {"CC && OD"}
                                                </option>
                                            </select>
                                        </div>
                                        <div className="col-md-6 mb-4">
                                            <label className="form-label">Select Type</label>
                                            <select
                                                name="loan-Type"
                                                className="form-select"
                                                value={inputValue['loan-Type']}
                                                onChange={(e) => handleInputChange(e, "loan-Type")}
                                            >
                                                <option value="" disabled>Select Type</option>
                                                <option key={1} value={"SALARIED"}>
                                                    {"SALARIED"}
                                                </option>
                                                <option key={2} value={"PARTNERSHIP"}>
                                                    {"PARTNERSHIP"}
                                                </option>
                                                <option key={3} value={"PROPRIETORSHIP"}>
                                                    {"PROPRIETORSHIP"}
                                                </option>

                                            </select>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {status === "query" && (
                                <div className="col-md-12 mb-4">
                                    <label className="form-label">Remarks</label>
                                    <input
                                        type="text"
                                        name="remarks"
                                        className="form-control"
                                        placeholder="Remarks"
                                        value={inputValue.remarks || ""}
                                        onChange={(e) => handleInputChange(e, "remarks")}
                                    />
                                </div>
                            )}

                            {status === "sanction" && (
                                <div className="col-md-12 mb-4">
                                    <label className="form-label">Amount</label>
                                    <input
                                        type="number"
                                        name="amount"
                                        className="form-control"
                                        placeholder="Amount"
                                        value={inputValue.amount || ""}
                                        onChange={(e) => handleInputChange(e, "amount")}
                                    />
                                </div>
                            )}

                            {status === "sanction" && (
                                <div className="col-md-12 mb-4">
                                    <label className="form-label">Rate</label>
                                    <input
                                        type="number"
                                        name="rate"
                                        className="form-control"
                                        placeholder="Rate"
                                        value={inputValue.rate || ""}
                                        onChange={(e) => handleInputChange(e, "rate")}
                                    />
                                </div>
                            )}

                            {status === "sanction" && (
                                <div className="col-md-12 mb-4">
                                    <label className="form-label">Tenure</label>
                                    <input
                                        type="number"
                                        name="tenure"
                                        className="form-control"
                                        placeholder="Tenure"
                                        value={inputValue.tenure || ""}
                                        onChange={(e) => handleInputChange(e, "tenure")}
                                    />
                                </div>
                            )}

                            {status === "login" && (
                                <>
                                    <div className="col-12 mb-4">
                                        <h4 className='mb-4'>Login Details</h4>
                                        <div className="row">
                                            <div className="col-md-6 mb-4">
                                                <label className="form-label">Loan Amount</label>
                                                <input
                                                    type="number"
                                                    name="loanAmount"
                                                    className="form-control"
                                                    placeholder="Loan Amount"
                                                    value={inputValue.loanAmount || ""}
                                                    onChange={(e) => handleInputChange(e, "loanAmount")}
                                                />
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <label className="form-label">Loan Account Number</label>
                                                <input
                                                    type="text"
                                                    name="loanAccountNumber"
                                                    className="form-control"
                                                    placeholder="Loan Account Number"
                                                    value={inputValue.loanAccountNumber || ""}
                                                    onChange={(e) => handleInputChange(e, "loanAccountNumber")}
                                                />
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <label className="form-label">Bank Name</label>
                                                <input
                                                    type="text"
                                                    name="bankName"
                                                    className="form-control"
                                                    placeholder="Bank Name"
                                                    value={inputValue.bankName || ""}
                                                    onChange={(e) => handleInputChange(e, "bankName")}
                                                />
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <label className="form-label">Product</label>
                                                <input
                                                    type="text"
                                                    name="product"
                                                    className="form-control"
                                                    placeholder="Product"
                                                    value={inputValue.product || ""}
                                                    onChange={(e) => handleInputChange(e, "product")}
                                                />
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <label className="form-label">SM Name</label>
                                                <input
                                                    type="text"
                                                    name="smName"
                                                    className="form-control"
                                                    placeholder="SM Name"
                                                    value={inputValue.smName || ""}
                                                    onChange={(e) => handleInputChange(e, "smName")}
                                                />
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <label className="form-label">Date of Birth</label>
                                                <input
                                                    type="date"
                                                    required
                                                    name="dateOfBirth"
                                                    className="form-control"
                                                    value={inputValue.dateOfBirth || ""}
                                                    onChange={(e) => handleInputChange(e, "dateOfBirth")}
                                                />
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <label className="form-label">Loan Date</label>
                                                <input
                                                    type="date"
                                                    name="loanDate"
                                                    className="form-control"
                                                    placeholder="Loan Amount"
                                                    value={inputValue.loanDate || ""}
                                                    onChange={(e) => handleInputChange(e, "loanDate")}
                                                />
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <label className="form-label">Code</label>
                                                <select
                                                    name="code"
                                                    className="form-select"
                                                    value={inputValue.code || ""}
                                                    onChange={(e) => handleInputChange(e, "code")}
                                                >
                                                    <option value="" disabled>Select Code</option>
                                                    {codes.map((code) => (
                                                        <option key={code.id} value={code.id}>
                                                            {code.code_name}
                                                        </option>
                                                    ))}
                                                </select>
                                                {!showAddCodeField && <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        onClick={handleAddCodeClick}
                                                    >
                                                        Add Code
                                                    </button>
                                                </div>}
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                {showAddCodeField &&
                                                    <div>
                                                        <label className="form-label">Code Name</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="New Code Name"
                                                            value={newCodeName}
                                                            onChange={(e) => setNewCodeName(e.target.value)}
                                                        />
                                                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                                                            <button
                                                                type="button"
                                                                className="btn btn-success ms-2"
                                                                onClick={handleSaveNewCode}
                                                            >
                                                                Save
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-success ms-2"
                                                                onClick={() => setShowAddCodeField(false)}
                                                            >
                                                                Close
                                                            </button>
                                                        </div>

                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {isProperty && <div className="col-12 mb-4">
                                        <h4 className='mb-4'>Property Details</h4>
                                        <div className="row">
                                            <div className="col-md-6 mb-4">
                                                <label className="form-label">Property Address</label>
                                                <textarea
                                                    type="text"
                                                    name="address"
                                                    className="form-control"
                                                    placeholder="Property Address"
                                                    value={inputValue.address || ""}
                                                    onChange={(e) => handleInputChange(e, "address")}
                                                />
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <label className="form-label">Square Feet</label>
                                                <input
                                                    type="text"
                                                    name="sqFeet"
                                                    className="form-control"
                                                    placeholder="Square Feet"
                                                    value={inputValue.sqFeet || ""}
                                                    onChange={(e) => handleInputChange(e, "sqFeet")}
                                                />
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <label className="form-label">Deed Amount</label>
                                                <input
                                                    type="text"
                                                    name="deedAmount"
                                                    className="form-control"
                                                    placeholder="Deed Amount"
                                                    value={inputValue.deedAmount || ""}
                                                    onChange={(e) => handleInputChange(e, "deedAmount")}
                                                />
                                            </div>
                                        </div>
                                    </div>}
                                </>
                            )}

                            {status === "disbursement" && (
                                <>
                                    <div className="col-12 mb-4">
                                        <h4 className='mb-4'>Disbursement Details</h4>
                                        <div className="row">
                                            <div className="col-md-6 mb-4">
                                                <label className="form-label">Disbursement Amount</label>
                                                <input
                                                    type="number"
                                                    name="disbursementAmount"
                                                    className="form-control"
                                                    placeholder="Disbursement Amount"
                                                    value={inputValue.disbursementAmount || ""}
                                                    onChange={(e) => handleInputChange(e, "disbursementAmount")}
                                                />
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <label className="form-label">Rate</label>
                                                <input
                                                    type="number"
                                                    name="disbursementRate"
                                                    className="form-control"
                                                    placeholder="Rate"
                                                    value={inputValue.disbursementRate || ""}
                                                    onChange={(e) => handleInputChange(e, "disbursementRate")}
                                                />
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <label className="form-label">Insurance</label>
                                                <select
                                                    name="insurance"
                                                    className="form-select"
                                                    value={inputValue.insurance || ""}
                                                    onChange={(e) => handleInputChange(e, "insurance")}
                                                >
                                                    <option value="" disabled>Select Insurance</option>
                                                    <option key={1} value={"Yes"}>
                                                        {"Yes"}
                                                    </option>
                                                    <option key={1} value={"No"}>
                                                        {"No"}
                                                    </option>

                                                </select>
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <label className="form-label">File Number</label>
                                                <input
                                                    type="text"
                                                    name="fileNumber"
                                                    className="form-control"
                                                    placeholder="File Number"
                                                    value={inputValue.fileNumber || ""}
                                                    onChange={(e) => handleInputChange(e, "fileNumber")}
                                                />
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <label className="form-label">Disbursement Date</label>
                                                <input
                                                    type="date"
                                                    name="disbursementDate"
                                                    className="form-control"
                                                    value={inputValue.disbursementDate || ""}
                                                    onChange={(e) => handleInputChange(e, "disbursementDate")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            {status === "partPayment" && (
                                <>
                                    <div className="col-12 mb-4">
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <h4 className="mb-4">Part Payment Details</h4>
                                            <div className="btn btn-primary" onClick={handleAddPart}>
                                                Add New Part
                                            </div>
                                        </div>
                                        <div className="row">
                                            {inputValue.parts?.map((part, index) => (
                                                <div key={index} className="col-12 mb-4">
                                                    <div className="row">
                                                        <div className="col-md-6 mb-4">
                                                            <label className="form-label">Part {index + 1} Amount</label>
                                                            <input
                                                                type="number"
                                                                name={`partAmount_${index}`}
                                                                className="form-control"
                                                                placeholder="Part Amount"
                                                                value={part.part_amount || ""}
                                                                onChange={(e) => handlePartInputChange(e, index, "part_amount")}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 mb-4">
                                                            <label className="form-label">Part {index + 1} Date</label>
                                                            <input
                                                                type="date"
                                                                name={`partDate_${index}`}
                                                                className="form-control"
                                                                value={part.part_date || ""}
                                                                onChange={(e) => handlePartInputChange(e, index, "part_date")}
                                                            />
                                                        </div>
                                                    </div>
                                                    {index > 0 && (
                                                        <div className="btn btn-danger" onClick={() => handleRemovePart(index)}>
                                                            Remove Part {index + 1}
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </>
                            )}



                        </>}
                    </div>

                    <button className="btn btn-blue" type="submit">{initialData ? 'Update' : 'Add'}</button>
                </form>
            </div>
        </div>
    );
};

export default LoanConsumerPopup;
