import React, { useState, useEffect } from 'react';
import './popup-u.css';
import { addBuilderUser, addMediclaimCompany, updateBuilderUser, updateMediclaimCompany } from '../serviceAPI/userAPI';
import { useToaster } from './Toaster';

const Popup = ({ isOpen, onClose, fetchApi, initialData }) => {
  const addToast = useToaster()
  console.log(initialData)
  const [formValues, setFormValues] = useState({
    name: ''
  });

  useEffect(() => {
    if (initialData && initialData.mediclaim_company_id) {
      setFormValues({
        name: initialData?.mediclaim_company_name
      });
    }else{
      setFormValues({
        name: ''
      });
    }
  }, [initialData]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async(e) => {
    e.preventDefault();

    const userData = {
      mediclaim_company_name:formValues.name.trim()
    };

    if (initialData && initialData.mediclaim_company_id) {
      userData.mediclaim_company_id = initialData.mediclaim_company_id
      const response = await updateMediclaimCompany(userData,addToast);
      if (response.status) {
        // setMessage('Builder User registered successfully!');
        fetchApi();
        onClose();
      } else {
        onClose();
      }
    } else {
      const response = await addMediclaimCompany(userData,addToast);
      if (response.status) {
        // setMessage('Builder User registered successfully!');
        fetchApi();
        onClose();
      } else {
        onClose();
      }
    }
    // addEntry(newEntry);
    // onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content" onClick={(e) => e.stopPropagation()}>
        <div className='popup-header d-flex justify-content-between'>
          <h2>{initialData ? 'Edit Mediclaim Company' : 'Add Mediclaim Company'}</h2>
          <span className="close-btn" onClick={onClose}>&times;</span>
        </div>
        <form className="popup-form" onSubmit={handleSubmit}>
          <div className='row'>
            <div className='col-md-6 mb-4'>
              <input type="text" name="name" className='form-control' placeholder='Company Name' value={formValues.name} onChange={handleInputChange} required />
            </div>
          </div>
          <button className='btn btn-blue' type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default Popup;
