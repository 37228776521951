import React, { useState, useEffect } from 'react';
import './popup-u.css';
import { addConsumerUnit, updateConsumerUnit } from '../serviceAPI/userAPI';
import Cookies from 'js-cookie';
import { useToaster } from './Toaster';
import FlagDropdown from '../pages/Flag';

const UnitPopup = ({ isOpen, onClose, fetchApi, initialData, mainData, fullData }) => {
  const addToast = useToaster();
  const [formState, setFormState] = useState({
    Name: initialData['user.username'] || '',
    MobileNumber: initialData['user.mobileNumber'] || '',
    Email: initialData['user.email'] || '',
    Sqfeet: initialData['user.sqFeet'] || '',
    SrNo: initialData['user.srNo'] || '',
    working_status: initialData['user.user_pk_id.status'] || '',
  });
  const [status, setStatus] = useState(initialData?.status || 'interested');
  const user = Cookies.get('user') && JSON.parse(Cookies.get('user')) || '';

  useEffect(() => {
    if (initialData) {
      setFormState({
        Name: initialData['user.username'] || '',
        MobileNumber: initialData['user.mobileNumber'] || '',
        Email: initialData['user.email'] || '',
        Sqfeet: initialData.sqFeet || '',
        SrNo: initialData.srNo || '',
        working_status: initialData['user.user_pk_id.status'] || '',
      });
      setStatus(initialData.status || 'interested');
    }
  }, [initialData]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let userData;
    if (status === 'interested') {
      const regex = /^\d{10}$/;
      if (!regex.test(formState.MobileNumber)) {
        alert('Mobile number is invalid');
        return;
      }
      userData = {
        username: formState.Name.trim(),
        mobileNumber: formState.MobileNumber,
        email: formState.Email,
        sqFeet: formState.Sqfeet,
        srNo: formState.SrNo,
        status: status,
        role_id: 5
      };
    } else {
      userData = {
        username: '',
        mobileNumber: '',
        email: '',
        sqFeet: '',
        srNo: '',
        role_id: 5,
        status: status
      };
    }

    userData.unit_id = fullData.unit_id;
    userData.builder_id = fullData.builder_id;
    userData.office_no = mainData.unitNumber;
    userData.floor_id = mainData?.floor?.floor_id;
    userData.wing_id = mainData?.floor?.wingId;
    userData.category_id = mainData?.category?.id;
    userData.builder_user_id = fullData?.['builderuser.user_id'];

    if (initialData.builderConsumerId) {
      userData.builderConsumerId = initialData.builderConsumerId;
      userData.user_id = initialData.user_id;

      const response = await updateConsumerUnit(userData, addToast);
      if (response.status) {
        fetchApi();
        onClose();
      }
    } else {
      const response = await addConsumerUnit(userData, addToast);
      if (response.status) {
        fetchApi();
        onClose();
      }
    }
  };

  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <div className="popup-header d-flex justify-content-between">
          <h2>{initialData.builderConsumerId ? 'Edit Unit Consumer' : 'Add Unit Consumer'}</h2>
          <span className="close-btn" onClick={onClose}>&times;</span>
        </div>
        <form className="popup-form" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-12 mb-4">
              <label className="form-label">Status</label>
              <div className="d-flex">
                <div className="me-4 d-flex align-items-center">
                  <input
                    type="radio"
                    className="me-2"
                    name="status"
                    value="interested"
                    checked={status === 'interested'}
                    onChange={() => setStatus('interested')}
                  />
                  <label>Interested</label>
                </div>
                <div className="d-flex align-items-center">
                  <input
                    type="radio"
                    name="status"
                    className="me-2"
                    value="notInterested"
                    checked={status === 'notInterested'}
                    onChange={() => setStatus('notInterested')}
                  />
                  <label>Not Interested</label>
                </div>
              </div>
            </div>
            {status === 'interested' && <>
              <div className="col-md-6 mb-4">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  name="Name"
                  className="form-control"
                  placeholder="Name"
                  value={formState.Name}
                  onChange={(e) => setFormState({ ...formState, Name: e.target.value })}
                />
              </div>
              <div className="col-md-6 mb-4">
                <label className="form-label">Mobile Number</label>
                <div style={{ display: 'flex' }}>
                  <FlagDropdown />
                  <input
                    type="text"
                    name="MobileNumber"
                    className="form-control"
                    placeholder="Mobile Number"
                    style={{ margin: '0' }}
                    value={formState.MobileNumber}
                    onChange={(e) => setFormState({ ...formState, MobileNumber: e.target.value })}
                  />
                </div>

              </div>
              <div className="col-md-6 mb-4">
                <label className="form-label">Email</label>
                <input
                  type="email"
                  name="Email"
                  className="form-control"
                  placeholder="Email"
                  value={formState.Email}
                  onChange={(e) => setFormState({ ...formState, Email: e.target.value })}
                />
              </div>
              <div className="col-md-6 mb-4">
                <label className="form-label">Sq. Ft.</label>
                <input
                  type="text"
                  name="Sqfeet"
                  value={formState.Sqfeet}
                  onChange={(e) => setFormState({ ...formState, Sqfeet: e.target.value })}
                  className="form-control"
                  placeholder="Sq. Ft."
                />
              </div>
              <div className="col-md-6 mb-4">
                <label className="form-label">Deed Amount</label>
                <input
                  type="text"
                  name="SrNo"
                  value={formState.SrNo}
                  onChange={(e) => setFormState({ ...formState, SrNo: e.target.value })}
                  className="form-control"
                  placeholder="Deed Amount"
                />
              </div>
              {formState.working_status && formState.working_status &&
                <div className="info-row">
                  <label className="form-label">Working Loan Status: <span className="text-green">{formState?.working_status?.toUpperCase() || ''}</span></label>
                </div>
              }
            </>}
          </div>
          {!formState.working_status && (
            <button className="btn btn-blue" type="submit">
              {initialData.builderConsumerId ? 'Update' : 'Add'}
            </button>
          )}
        </form>
      </div>
    </div>
  );
};

export default UnitPopup;
