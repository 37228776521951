import React, { useEffect, useState } from 'react';
import './Loanni.css';
import Table from '../components/Table';
import DashboardLayout from '../components/DashboardLayout';
import { getAllConsumers, getAllLoanNotInterestedConsumer, getAllVerticle, getAllVerticleUser } from '../serviceAPI/userAPI';
import TableConsumerLoan from '../components/Loan-Table/Loan-Consumer';
import StatusModal from '../components/Loan-status.popup';

const Loanni = () => {
  const initialData = [
    { Name: 'John Doe', 'Mobile Number': '1234567890', Email: 'john@example.com' },
    { Name: 'Jane Smith', 'Mobile Number': '9876543210', Email: 'jane@example.com' },
    { Name: 'Samuel Green', 'Mobile Number': '5555555555', Email: 'sam@example.com' },
  ];

  const [data, setData] = useState([]);
  const [verticle, setVerticle] = useState([]);
  const [verticleUser, setVerticleUser] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [heading, setHeading] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
    setEditIndex(null);
  };

  useEffect(() => {
    getAllLoanConsumerData()
  }, []);

  const getAllLoanConsumerData = async () => {
    const consumerData = await getAllLoanNotInterestedConsumer();
    if (consumerData?.data && consumerData?.data?.length) {
      setData(consumerData?.data);
    } else {
      setData([]);
    }
    setHeading([{ key: 'userConsumers.username', head: 'Name' }, { key: 'userConsumers.email', head: 'Email' }, { key: 'userConsumers.mobileNumber', head: 'Mobile Number' }]);
  };

  const handleEdit = (index) => {
    setEditIndex(index);
    setIsPopupOpen(true);
  };

  const handleSort = (column) => {
    const direction = sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortColumn(column);
    setSortDirection(direction);
  };

  const fetchApi = () => {
    getAllLoanConsumerData();
  };

  return (
    <DashboardLayout>
      <div className='d-flex justify-content-between align-items-center dashboard-title'>
        <h1>Loan</h1>
      </div>
      {isPopupOpen && <StatusModal
        isOpen={isPopupOpen}
        onClose={togglePopup}
        fetchApi={fetchApi}
        notInterested={true}
        initialData={editIndex !== null ? data[editIndex] : null}
      />}
      {(
        <TableConsumerLoan
          headings={heading}
          data={data}
          verticleOptions={verticle}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          itemsPerPage={itemsPerPage}
          handleEdit={handleEdit}
          handleSort={handleSort}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          setItemsPerPage={setItemsPerPage}
        />
      )}
    </DashboardLayout>
  );
};

export default Loanni;
