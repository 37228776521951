import React, { useEffect, useState } from 'react';
import './Mediclaim.css';
import Table from '../components/Table';
import Popup from '../components/Mediclaim-popup';
import DashboardLayout from '../components/DashboardLayout';
import { getAllConsumers, getAllMedicalimConsumer, getAllMedicalimConsumerData, getAllVerticle, getAllVerticleUser } from '../serviceAPI/userAPI';
import viewImage from './view.png';
import Cookies from 'js-cookie';

const Mediclaim = () => {

  const [data, setData] = useState([]);
  const [verticle, setVerticle] = useState([]);
  const [verticleUser, setVerticleUser] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [view, setView] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [heading, setHeading] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  const categoryId = Cookies.get('category');
  const user = (Cookies.get('user') && JSON.parse(Cookies.get('user'))) || '';

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
    setView(false)
    setEditIndex(null);
  };

  useEffect(() => {
    getAllMediclaimConsumerData()
  }, []);

  const getAllMediclaimConsumerData = async () => {
    const consumerData = await getAllMedicalimConsumerData();
    if (consumerData?.data && consumerData?.data?.length) {
      const processedData = consumerData?.data.map((item,index) => ({
        ...item,
        view: <a><img className="building-view" src={viewImage} alt="View" style={{ cursor: 'pointer' }} onClick={() => handleViewClick(item,index)} /></a>
    }));
      setData(processedData);
    } else {
      setData([]);
    }
    setHeading([{ key: 'user.username', head: 'Name' }, { key: 'user.email', head: 'Email' }, { key: 'user.mobileNumber', head: 'Mobile Number' }, { key: 'view', head: 'View' }]);
  };


  const handleViewClick = (item,index) => {
    // Add your view click handling logic here
    console.log('View clicked for item:', item);
    setEditIndex(index);
    setView(true)
    setIsPopupOpen(true);
    // navigate(`/mediclaim/company/${item.mediclaim_company_id}`)
};

  const handleEdit = (index) => {
    setEditIndex(index);
    setIsPopupOpen(true);
  };

  const handleSort = (column) => {
    const direction = sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortColumn(column);
    setSortDirection(direction);
  };

  const fetchApi = () => {
    getAllMediclaimConsumerData();
  };

  const handleVerticalChange = (index, newVertical) => {
    const updatedData = data.map((item, idx) =>
      idx === index ? { ...item, vertical: newVertical } : item
    );
    setData(updatedData);
  };

  return (
    <DashboardLayout>
      <div className='d-flex justify-content-between align-items-center dashboard-title'>
        <h1>Mediclaim</h1>
        {((user && user.role_id !== 1) || (categoryId && categoryId.includes(4))) && <button className="btn btn-white" onClick={togglePopup}>Add</button>}
        {isPopupOpen && <Popup
          isOpen={isPopupOpen}
          onClose={togglePopup}
          fetchApi={fetchApi}
          initialData={editIndex !== null ? data[editIndex] : null}
          view={view}
        />}
      </div>
      {(
        <Table
          headings={heading}
          data={data}
          verticleOptions={verticle}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          itemsPerPage={itemsPerPage}
          handleEdit={handleEdit}
          handleSort={handleSort}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          setItemsPerPage={setItemsPerPage}
          onVerticalChange={handleVerticalChange}
        />
      )}
    </DashboardLayout>
  );
};

export default Mediclaim;
